import React from 'react';
import { withRouter } from 'react-router';

const DelayLink = ({ delay, to, history, children, onStart }) => {
  const delayRedirect = event => {
    event.preventDefault();

    onStart();
    setTimeout(() => history.push(to), delay);
  };

  return (
    <a href={to} onClick={delayRedirect}>
      {children}
    </a>
  );
};

export default withRouter(DelayLink);
