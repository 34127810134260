import React, { Fragment } from 'react';
import SvgRender from 'components/general/SvgRender';
import MenuLink from './MenuLink';

import logoSvg from 'assets/svg/logos/logo-full.svg';
import logoMobile from 'assets/svg/logos/logo-T.svg';

const Sidebar = ({ menuIsOpen, setMenuIsOpen }) => {
  return (
    <Fragment>
      <div className="sidebar">
        <div className="logo">
          <MenuLink to="/" setMenuIsOpen={setMenuIsOpen}>
            <SvgRender className="max-height-120" src={logoSvg} />
          </MenuLink>
        </div>
        <div
          className={`burger ${menuIsOpen ? 'open' : ''}`}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <span />
          <span />
          <span />
        </div>
        <div className="font-weight-bold login font-14">
          <a href={process.env.REACT_APP_MEMBERS_SITE_URL} target="_self">
            LOG IN
          </a>
        </div>
      </div>

      <div className="sidebar-mobile">
        <div className="logo d-inline-flex">
          <MenuLink to="/" setMenuIsOpen={setMenuIsOpen}>
            <span className="logo-full d-none d-md-flex">
              <SvgRender src={logoSvg} />
            </span>
            <span className="logo-mobile d-inline-flex flex-column align-items-center justify-content-center">
              <SvgRender src={logoMobile} className="logo-mobile-icon" />
            </span>
          </MenuLink>
        </div>
        <div
          className={`burger ${menuIsOpen ? 'open' : ''}`}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
