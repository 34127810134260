import React, { useState } from 'react';
import ErrorMessage from './ErrorMessage';

const Input = ({
  placeholder,
  value,
  className,
  onChange,
  placeholderSm,
  name,
  error,
  status,
  type = 'text',
  ...optionals
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={`input-wrapper ${focused || value ? 'focused' : ''} mb-2 mb-md-3 mb-xl-4 ${
        error ? 'error' : ''
      } ${className ? className : ''}`}
    >
      <div
        className={`input-placeholder ${placeholderSm ? 'placeholder-sm' : ''} ${
          value ? 'with-value' : ''
        }`}
      >
        {placeholder}
      </div>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : status ? (
        <ErrorMessage>{status}</ErrorMessage>
      ) : null}
    </div>
  );
};

export default Input;
