import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import PageNavigation from './PageNavigation';
import Cover from './Cover';

import Helmet from 'components/general/Helmet';

const SinglePage = ({
  page: { name, abbrev, coverType, content, cover, tabs, coverStyle, abbrevClass, coverMobile },
  history,
  title
}) => {
  const [activePage, setActivePage] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (name !== activePage) {
      setIsLoaded(false);
      setActivePage(name);
    }
  }, [name]);

  useEffect(() => {
    if (activePage) {
      setIsLoaded(true);
    }
  }, [activePage]);

  useEffect(() => {
    if (history.location.hash !== '') {
      const activePage = history.location.hash.replace('#', '');
      let active = tabs.find(f => f.key == activePage);
      if (active) {
        setTimeout(() => scrollToPage(active.key), 400);
      }
    }
  }, [isLoaded]);

  const renderActiveContent = () => {
    const ActiveContent = content;
    return <ActiveContent />;
  };

  const scrollToPage = page => {
    const scrollTo = document.querySelector(`.secondary-section.${page}`);
    if (scrollTo) {
      document.querySelector('.app').scrollTo({
        top: scrollTo.offsetTop - 50,
        left: 0,
        behavior: 'smooth'
      });
      window.history.pushState('', '', `${history.location.pathname}#${page}`);
    }
  };

  return (
    <div className={`page screen-divided-effect ${isLoaded ? 'is-loaded' : ''}`} key={activePage}>
      <Helmet title={title} />
      <Cover
        coverMobile={coverMobile}
        key={abbrev}
        coverStyle={coverStyle}
        cover={cover}
        coverType={coverType}
      />
      <PageNavigation
        abbrevClass={abbrevClass}
        name={name}
        abbrev={abbrev}
        tabs={tabs}
        scrollToPage={scrollToPage}
      />
      {renderActiveContent()}
    </div>
  );
};

export default withRouter(SinglePage);
