import React from 'react';
import JoinUs from './join-us';
import Career from './Career';
import Legal from './legal';
import About from './About';
import PartOfTact from './PartOfTact';

const MenuPanel = ({ activePanel, mobileView, setActivePanel, activeIndex, setActiveIndex }) => {
  return (
    <div className={`menu-content d-flex ${activePanel}`}>
      <About setActivePanel={setActivePanel} />
      {activePanel === 'part_of_tact' ? <PartOfTact setActivePanel={setActivePanel} /> : ''}
      <JoinUs setActivePanel={setActivePanel} />
      <Career setActivePanel={setActivePanel} />
      <Legal
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        mobileView={mobileView}
      />
    </div>
  );
};

export default MenuPanel;
