import React from 'react';
import DelayLink from 'components/general/DelayLink';

const MenuLink = ({ children, to, setMenuIsOpen }) => (
  <DelayLink to={to} delay={300} onStart={() => setMenuIsOpen(false)}>
    {children}
  </DelayLink>
);

export default MenuLink;
