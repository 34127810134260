export const getInitialValues = (defaultValues, active) => {
  let initialValues = {};

  Object.keys(defaultValues).forEach((key, index) => {
    if (active[key] || active[key] === false) {
      initialValues[key] = active[key];
    } else {
      initialValues[key] = defaultValues[key];
    }
  });

  return initialValues;
};
