import React from 'react';
import { Link } from 'react-router-dom';

import SvgRender from 'components/general/SvgRender';

import stepsPreviousSvg from 'assets/svg/common/steps-previous.svg';
import stepsNextSvg from 'assets/svg/common/steps-next.svg';

const PageBottomNav = ({ label, path, next, previous }) => {
  return (
    <div
      className={`page-bottom-nav btn-with-arrow ${
        previous ? 'page-bottom-nav--previous btn-with-arrow--prev' : ''
      }`}
    >
      <Link to={path} className="d-inline-block">
        <div className="page-bottom-nav--label gotham-300">{label}</div>
        <div className="page-bottom-nav--direction">
          {previous && <SvgRender style={{ height: 20, width: 8 }} src={stepsPreviousSvg} />}
          <span className={`gotham-700 p${previous ? 'l' : 'r'}-1`}>
            {next ? 'Next' : 'Previous'}
          </span>
          {next && <SvgRender style={{ height: 20, width: 8 }} src={stepsNextSvg} />}
        </div>
      </Link>
    </div>
  );
};

export default PageBottomNav;
