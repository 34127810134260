import React from 'react';
import MenuLink from './MenuLink';
import { Link } from 'react-router-dom';

const MenuLinks = ({ setMenuIsOpen, activePanel, setActivePanel, setIsFlipped }) => {
  const links = [
    {
      firstLevel: {
        text: 'Be Active',
        link: '/be-active'
      },
      secondaryLevel: [
        {
          text: 'Tennis',
          link: '/be-active/tennis'
        },
        {
          text: 'Fitness',
          link: '/be-active/fitness'
        }
      ]
    },

    {
      firstLevel: {
        text: 'Be Vigorous',
        link: '/be-vigorous'
      },
      secondaryLevel: [
        {
          text: 'Cryotherapy',
          link: '/be-vigorous#cryotherapy'
        },
        {
          text: 'Physiotherapy',
          link: '/be-vigorous#physiotherapy'
        },
        {
          text: 'Spa',
          link: '/be-vigorous#spa'
        },
        {
          text: 'Nutrition',
          link: '/be-vigorous#nutrition'
        }
      ]
    },

    {
      firstLevel: {
        text: 'Eat Well',
        link: '/eating-well'
      },
      secondaryLevel: [
        {
          text: 'From Farm to Table',
          link: '/eating-well#from-farm-to-table'
        },
        {
          text: 'Indoor & Outdoor Dining',
          link: '/eating-well#indoor-and-outdoor-dining'
        },
        {
          text: 'The Cookhouse',
          link: '/eating-well#the-cookhouse'
        }
      ]
    },

    {
      firstLevel: {
        text: 'Train the Mind',
        link: '/train-the-mind'
      },
      secondaryLevel: [
        {
          text: 'Nature',
          link: '/train-the-mind#nature'
        },
        {
          text: 'Culture',
          link: '/train-the-mind#culture'
        },
        {
          text: 'Seminars & Talks',
          link: '/train-the-mind#seminars'
        }
      ]
    },

    {
      firstLevel: {
        text: 'Be our Guest',
        link: '/be-our-guest'
      },
      secondaryLevel: [
        {
          text: 'The Guesthouse',
          link: '/be-our-guest#the-guesthouse'
        },
        {
          text: 'The Business Hub',
          link: '/be-our-guest#the-business-hub'
        }
      ]
    },

    {
      firstLevel: {
        key: 'part_of_tact',
        text: 'Be Sustainable',
        sideDrawerLink: true,
        link: ''
      },
      secondaryLevel: []
    }
  ];

  const onMouseEnter = e => {
    const el = e.target.className.indexOf('first-level') > -1 ? e.target : e.target.parentNode;
    const link = el.querySelector('a');
    const translate = link ? el.offsetWidth - link.offsetWidth - 56 : null;
    const arrow = el.querySelector('.arrow');
    if (!arrow) return;

    arrow.style.transform = `translateX(${translate}px)`;
  };

  const onMouseLeave = e => {
    const el =
      e.target.className.indexOf('first-level') > -1 ? e.target : e.target.closest('.first-level');
    const arrow = el.querySelector('.arrow');
    if (!arrow) {
      return;
    }
    arrow.style.transform = `translateX(0px)`;
  };

  const handleActionClick = panel => {
    if (activePanel === panel) {
      setIsFlipped(false);
      setTimeout(() => {
        setActivePanel('');
      }, 300);
    } else {
      setActivePanel(panel);
    }
  };

  return (
    <div className="menu-links">
      {links.map((item, index) => (
        <div
          key={index}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="first-level"
        >
          <div className="first-level-title">
            {item.firstLevel.sideDrawerLink ? (
              <div className="d-inline" onClick={() => handleActionClick(item.firstLevel.key)}>
                {item.firstLevel.text}
              </div>
            ) : (
              <MenuLink to={item.firstLevel.link} setMenuIsOpen={setMenuIsOpen}>
                {item.firstLevel.text}
              </MenuLink>
            )}
            <div className="arrow" />
          </div>

          <div className="second-level">
            {item.secondaryLevel.map((secondaryItem, index) => (
              <div key={index} className="second-level-title">
                <MenuLink to={secondaryItem.link} setMenuIsOpen={setMenuIsOpen}>
                  {secondaryItem.text}
                </MenuLink>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuLinks;
