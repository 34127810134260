import React from 'react';
import Page from 'components/page';
import Tennis from './Tennis';
import Fitness from './Fitness';

import coverTennis from 'assets/videos/tennis.mp4';
import coverFitness from 'assets/videos/fitness.mp4';

const BeActive = props => {
  const page = {
    name: 'Be Active',
    abbrev: 'GO',
    tabs: [
      {
        button: 'TENNIS',
        coverStyle: { objectPosition: 'center' },
        cover: coverTennis,
        coverType: 'video',
        content: Tennis,
        key: 'tennis'
      },
      {
        button: 'FITNESS',
        coverStyle: { objectPosition: 'center' },
        cover: coverFitness,
        coverType: 'video',
        content: Fitness,
        key: 'fitness'
      }
    ]
  };

  return <Page page={page} title="Tennis - Enjoying The Game at Tatoi Club" />;
};

export default BeActive;
