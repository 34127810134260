import { useEffect } from 'react';

export const useInsertionToViewportCallback = (sectionRef, insertionCallbacks) => {
  useEffect(() => {
    let lastScrollTop = 0;
    var isScrolling = null;

    //trigger event first time
    const app = document.querySelector('.app');

    app.dispatchEvent(new Event('scroll'));
    app.addEventListener(
      'scroll',
      () => {
        if (isScrolling !== null) {
          clearTimeout(isScrolling);
        }

        isScrolling = setTimeout(function() {
          //check for mouse scroll direction
          // const st = document.body.pageYOffset || document.body.scrollTop;
          const st = app.scrollTop;

          const direction = st > lastScrollTop ? 'down' : 'up';

          if (sectionRef.current) {
            const rect = sectionRef.current.getBoundingClientRect();
            const { top, bottom, left, right, height } = rect;
            // const vHeight = document.body.innerHeight;
            const vHeight = window.innerHeight;

            insertionCallbacks.forEach(action => {
              //check for insertion
              const topInsertion =
                top +
                (action.insertion ? action.insertion : 0) +
                (action.includeHeight ? height : 0);
              const bottomInsertion =
                bottom - (action.bottomInsertion ? action.bottomInsertion : 0);

              //check visibility
              const isVisible = action.topOnly
                ? vHeight - topInsertion > 0
                : (topInsertion > 0 || bottomInsertion > 0) && topInsertion < vHeight;

              //get visible height of div in viewport
              const visibleHeight = vHeight - top;

              if (isVisible) {
                action.callback({
                  direction,
                  top,
                  bottom,
                  left,
                  right,
                  height,
                  vHeight,
                  visibleHeight,
                  el: sectionRef.current
                });
              } else {
                action.offCallback &&
                  action.offCallback({
                    direction,
                    topInsertion,
                    bottomInsertion,
                    left,
                    right,
                    height,
                    vHeight,
                    el: sectionRef.current
                  });
              }
            });
          }

          lastScrollTop = st <= 0 ? 0 : st;
        }, 66);
      },
      false
    );
  }, []);
};
