import React from 'react';
import { useState } from 'react';
import Input from 'components/inputs/Input';
import File from 'components/inputs/File';
import SvgRender from 'components/general/SvgRender';
import { Button } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { post } from 'utils/api';

import stepsArrowSvg from 'assets/svg/common/steps-next.svg';

const Career = () => {
  const handleFileUpload = setFieldValue => e => {
    const { target: { files } = {} } = e;

    const fr = new FileReader();
    fr.onload = () => {};

    if (files.length > 0) {
      setFieldValue('cv', files[0]);
      fr.readAsDataURL(files[0]);
    }
  };

  const [submitted, setSubmitted] = useState(false);
  const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

  return (
    <div className="menu-panel overflow-hidden h-100">
      <div className="menu-panel-title">CAREER</div>
      <div className="menu-panel-header font-weight-bold font-18 font-xl-32 mb-2">
        Interested in working with us?
      </div>
      <div className="menu-panel-sub-header font-weight-light text-primary-fade mb-2 mb-lg-4 mb-xl-7 font-12 font-sm-14 font-xl-22">
        Let us know about your skills and aspirations.
      </div>

      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          position: '',
          phone: '',
          cv: ''
        }}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required('*Required'),
          lastname: Yup.string().required('*Required'),
          email: Yup.string().email('*This email is invalid').required('*Required'),
          phone: Yup.string().matches(phoneRegExp, '*This number is invalid').required('*Required'),
          position: Yup.string().required('*Required'),
          cv: Yup.mixed().required('*Required')
        })}
        onSubmit={async (values, { setErrors, setStatus, ...actions }) => {
          let formData = new FormData();

          formData.set('firstname', values.firstname);
          formData.set('lastname', values.lastname);
          formData.set('email', values.email);
          formData.set('phone', values.phone);
          formData.set('position', values.position);
          formData.set('cv', values.cv);

          try {
            await post('/contact/career', formData).then(res => {
              if (res.status === 200 && res.data.msg === 'request_submitted') {
                setSubmitted(true);
              }
            });
          } catch (error) {
            setStatus('server_error');
          }
        }}
        render={({
          values,
          errors,
          status,
          setFieldValue,
          handleChange,
          isSubmitting,
          submitCount,
          setIs,
          setSubmitting,
          setStatus
        }) => {
          if (submitted && status != 'server_error') {
            return (
              <div className="success-message d-flex flex-column">
                <h3 className="font-22 font-weight-light line-height-42">
                  Thanks for filling out our form!
                </h3>
                <span className="font-16 font-weight-light text-primary-fade">
                  We’ll get back to you shortly.
                </span>
              </div>
            );
          } else if (status == 'server_error') {
            return (
              <div className="success-message d-flex flex-column">
                <h3 className="font-20 font-weight-light line-height-42">
                  Your form is not submitted successfully.
                </h3>
                <span className="font-16 font-weight-light text-primary-fade">
                  Please
                  <Button
                    color="link"
                    className="text-blue"
                    onClick={() => {
                      setStatus('');
                      setSubmitted(false);
                      setSubmitting(false);
                    }}
                  >
                    Try again.
                  </Button>
                </span>
              </div>
            );
          } else {
            return (
              <Form className="h-100 flex-fill overflow-hidden">
                <div className="form-wrapper max-width-480">
                  <div className="form-wrapper--inputs">
                    <Input
                      key="firstname"
                      type="text"
                      name="firstname"
                      placeholder="First Name"
                      error={submitCount > 0 && errors.firstname}
                      status={status && status.firstname}
                      onChange={handleChange}
                      value={values.firstname}
                    />

                    <Input
                      key="lastname"
                      type="text"
                      name="lastname"
                      placeholder="Last Name"
                      error={submitCount > 0 && errors.lastname}
                      status={status && status.lastname}
                      onChange={handleChange}
                      value={values.lastname}
                    />

                    <Input
                      key="email"
                      type="text"
                      name="email"
                      placeholder="Email"
                      error={submitCount > 0 && errors.email}
                      status={status && status.email}
                      onChange={handleChange}
                      value={values.email}
                    />

                    <Input
                      key="phone"
                      type="tel"
                      name="phone"
                      placeholder="Phone number"
                      error={submitCount > 0 && errors.phone}
                      status={status && status.phone}
                      onChange={handleChange}
                      value={values.phone}
                    />

                    <Input
                      key="position"
                      type="text"
                      name="position"
                      placeholder="Position"
                      error={submitCount > 0 && errors.position}
                      status={status && status.position}
                      onChange={handleChange}
                      value={values.position}
                    />

                    <File
                      key="cv"
                      type="file"
                      name="cv"
                      placeholder="Upload your cv"
                      error={submitCount > 0 && errors.cv}
                      status={status && status.cv}
                      onChange={handleFileUpload(setFieldValue)}
                      value={values.cv}
                      className="mb-4"
                      accept="application/msword, text/plain, application/pdf, .pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                  </div>
                  <div className="form-wrapper--actions d-flex justify-content-end">
                    <Button
                      type="submit"
                      disabled={isSubmitting && !status}
                      color="link"
                      className="d-flex align-items-center p-0 font-weight-bold btn-with-arrow"
                    >
                      <span className="pr-1 ">Send</span>
                      <SvgRender style={{ height: 20, width: 8 }} src={stepsArrowSvg} />
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }
        }}
      />
    </div>
  );
};

export default Career;
