import React from 'react';
import Page from 'components/page';

import EatWellPage from './EatWell';
// *****
//
// For more information of how to structure this page
// check the implementation on views/be-active/index
//
// *****

import eatWell from 'assets/videos/eat-well.mp4';

import SinglePage from 'components/page/SinglePage';

const EatWell = props => {
  const page = {
    name: 'Eat Well',
    abbrev: 'DO',
    cover: eatWell,
    coverType: 'video',
    content: EatWellPage,
    coverStyle: { objectPosition: 'center' },
    tabs: [
      {
        button: 'FROM FARM TO TABLE',
        key: 'from-farm-to-table'
      },
      {
        button: 'INDOOR & OUTDOOR DINING',
        key: 'indoor-and-outdoor-dining'
      },
      {
        button: 'THE COOKHOUSE',
        key: 'the-cookhouse'
      }
    ]
  };

  return <SinglePage page={page} title="Eating Well at Tatoi Club" />;
};

export default EatWell;
