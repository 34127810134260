import React, { useState, useRef } from 'react';
import { useInsertionToViewportCallback } from 'utils/hooks';

const PageNavigation = ({
  name,
  abbrev,
  tabs,
  activeTabIndex,
  setActiveTabIndex,
  scrollToPage,
  abbrevClass
}) => {
  const fancyBox = useRef(null);
  const [scrollhasDone, setScrollHasDone] = useState(false);

  const insertionCallbacks = [
    {
      insertion: 0,
      callback: val => animate(val)
    }
  ];

  const animate = val => {
    let translateTo = ((val.visibleHeight - val.top) / 100) * 8;
    translateTo = translateTo > 100 ? 100 : translateTo < 0 ? 0 : translateTo;

    if (window.innerWidth < 1280) {
      translateTo = translateTo < 50 ? translateTo : 50;
    }

    if (window.innerWidth < 768) {
      translateTo = translateTo < 30 ? translateTo : 30;
    }

    const el = document.querySelector('.fancy-box');
    const transform = `translate3d(0px,${translateTo}px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)`;
    el.style.transform = transform;
  };

  useInsertionToViewportCallback(fancyBox, insertionCallbacks);

  setTimeout(() => {
    setScrollHasDone(true);
  }, 500);

  const renderButtons = () => {
    return (
      <div className={`page-tabs  ${scrollhasDone ? 'active' : ''}`}>
        {tabs.map((t, index) => (
          <div
            key={t.button}
            className={`page-tabs--button ${index === activeTabIndex ? 'active' : ''}`}
            onClick={() =>
              scrollToPage
                ? scrollToPage(t.key)
                : setActiveTabIndex({ index, force: true, key: t.key })
            }
          >
            <span>{t.button}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="page-navigation" ref={fancyBox}>
      <div className={`fancy-box ${abbrevClass || ''} ${scrollhasDone ? 'animate' : ''}`}>
        <div className="fancy-box--inner narrow-700">
          <span className="fancy-box--abbrev">{abbrev}</span>
        </div>
      </div>

      <div className="page-navigation--main">
        <div className={`page-navigation--title narrow-700 ${scrollhasDone ? 'active' : ''}`}>
          <h1>{name}</h1>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

export default PageNavigation;
