import React, { Fragment } from 'react';
import PrimarySection from 'components/page/PrimarySection';
import SecondarySection from 'components/page/SecondarySection';
import SectionsHeader from 'components/page/SectionsHeader';
import Helmet from 'components/general/Helmet';

import gettingFit from 'assets/images/be-active/fitness/getting-fit.jpg';

import indoorOutdoorPrimary from 'assets/images/be-active/fitness/indoor-outdoor-primary.jpg';
import indoorOutdoorSecondary from 'assets/images/be-active/fitness/indoor-outdoor-secondary.jpg';

import classicalAthleticsPrimary from 'assets/images/be-active/fitness/classic-athletics-primary.jpg';
import classicalAthleticsSecondary from 'assets/images/be-active/fitness/classic-athletics-secondary.jpg';
import ScrollButton from 'components/general/ScrollButton';
import PageBottomNav from 'components/general/PageBottomNav';

const Fitness = () => {
  return (
    <Fragment>
      <Helmet
        title="Fitness - Getting Fit at Tatoi Club"
        content="Fitness at Tatoi Club: Indoor & Outdoor training areas. Wide range of group classes. Fully personalized fitness programmes. Click here to find out more!"
      />
      <PrimarySection
        title="GETTING FIT"
        text="A fully equipped gym for functional training is available for an intense workout under the guidance of the team of Fitness Trainers."
        image={gettingFit}
        leftText={'Be Active'}
      />
      <SectionsHeader header="Perfect Environment" animate />
      <SecondarySection
        title="INDOOR & OUTDOOR"
        text="A wide range of group classes take place in both indoor and outdoor training areas, while fully personalized fitness programmes under the continuous supervision of a personal trainer offer the best results in the shortest time."
        primaryImage={indoorOutdoorPrimary}
        secondaryImage={indoorOutdoorSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
      />
      <SecondarySection
        title="CLASSICAL ATHLETICS"
        text="Track n’ field is historically linked with the values of the Olympic Games, competition, effort, personal growth, respect for the competitor and rules and regulations and is the basis for training for all sports. The philosophy of the Track n’ Field Academy is to train with a long-term horizon."
        primaryImage={classicalAthleticsPrimary}
        secondaryImage={classicalAthleticsSecondary}
        primaryImageAtRight
        animateSection={true}
        alignRight
        offsetLeft={76}
        offsetRight={76}
      />
      <ScrollButton />
      <PageBottomNav label="TENNIS" path="/be-active/tennis" previous />
    </Fragment>
  );
};

export default Fitness;
