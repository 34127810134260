import React, { Fragment } from 'react';
import PrimarySection from 'components/page/PrimarySection';
import SecondarySection from 'components/page/SecondarySection';
import SectionsHeader from 'components/page/SectionsHeader';

import totalHarmony from 'assets/images/be-our-guest/total-harmony-1.jpg';

import guestHousePrimary from 'assets/images/be-our-guest/guest-house-primary.jpg';
import guestHouseSecondary from 'assets/images/be-our-guest/guest-house-secondary.jpg';
import guesthouse_2_1 from 'assets/images/be-our-guest/guesthouse-2.1.jpg';
import guesthouse_2_2 from 'assets/images/be-our-guest/guesthouse-2.2.jpg';

import bussinessHubPrimary from 'assets/images/be-our-guest/business-hub-primary.jpg';
import bussinessHubSecondary from 'assets/images/be-our-guest/business-hub-secondary.jpg';

import ScrollButton from 'components/general/ScrollButton';
const BeOurGuest = () => {
  const primaryImages = [guestHousePrimary, guesthouse_2_1];
  const secondaryImages = [guestHouseSecondary, guesthouse_2_2];

  return (
    <Fragment>
      <PrimarySection
        title="IN TOTAL HARMONY WITH NATURE"
        text="An unforgettable experience away from home yet so close to it."
        image={totalHarmony}
        leftText={'Be our Guest'}
      />
      <SectionsHeader header="Ideal Conditions" animate />
      <SecondarySection
        title="THE GUESTHOUSE"
        text="Guests have the opportunity to live a holistic wellness experience, relax and enjoy our warm hospitality in a serene natural setting. Two suites and six rooms which can host up to four guests each make them feel at home, while enjoying access to the Club’s services and facilities."
        animateSection={true}
        primaryImages={primaryImages}
        secondaryImages={secondaryImages}
        // primaryImageAtRight
        alignRight
        hasSlides={true}
        slideImages
        offsetLeft={76}
        offsetRight={76}
        className="the-guesthouse"
      />
      <SecondarySection
        className="the-business-hub"
        title="THE BUSINESS HUB"
        text="A business visitor can find at the Club the ideal conditions during his stay, combining work with leisure.  Corporate business meeting and conference facilities offer a choice of two Meeting rooms and a multifunctional hall equipped with new state of the art business facilities."
        primaryImage={bussinessHubPrimary}
        secondaryImage={bussinessHubSecondary}
        primaryImageAtRight
        alignRight
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
      />

      <ScrollButton />
    </Fragment>
  );
};

export default BeOurGuest;
