import React from 'react';

const CameraPolicy = () => {
  return (
    <div>
      <p>
        <strong>Aim and application</strong>
      </p>
      <ul>
        <li>
          The current policy describes the terms for the installation and operation of the video
          surveillance system via closed circuit TV (CCTV) of the company TATOI CLUB T.C. Limited,
          22 Erythraias Street. It also defines the measures which are applied to protect the
          personal data of individuals who are recorded by the closed-circuit system.
        </li>
        <li>
          The CCTV system which has been installed in our premises allows for the recording and
          transmission of images and sounds to display screens and recording machines.
        </li>
        <li>
          The purpose of this system is the safety and protection of our personnel, Members, guests,
          office spaces, athletic facilities, installations, property, vehicles and other assets and
          information of TATOI CLUB, after an assessment of the rights of the subjects.
        </li>
      </ul>
      <p>
        <strong>Responsibilities of Data Controller</strong>
      </p>
      <ul>
        <li>
          TATOI CLUB is the Data Controller and has the obligations arising from EU Regulation No
          2016/679 (GDPR), Law 4624/2019 and the guidelines of the Hellenic Data Protection
          Authority.
        </li>
        <li>
          TATOI CLUB is responsible for safeguarding data subjects’ rights under the GDPR, in
          particular the right to be informed (use of special signage in Greek and English
          mentioning the relevant Law and the contact information of the Data Controller/Data
          Protection Officer, publication of the CCTV policy on the company’s web page, informing
          staff etc.), as well as the right to object. In addition, TATOI CLUB fully complies with
          the obligation to safeguard the confidentiality and security of the data processing.
        </li>
      </ul>
      <p>
        <strong>Video Surveillance System Policy</strong>
      </p>
      <ul>
        <li>
          The legal ground for the operation of the TATOI CLUB video surveillance system is the
          legitimate interest to safeguard the security and safety of staff, customers, Members,
          suppliers and facilities, as well as the protection of persons and goods from criminal
          acts.{' '}
        </li>
        <li>
          TATOI CLUB has carefully assessed the above legitimate interests and the interests and
          freedoms of the data subjects. In particular, it has taken into account that the premises
          of TATOI CLUB are located in an area of 75,000 m2, neighbouring a forest where the risk of
          fire is particularly high, as demonstrated by the recent fire that destroyed a large part
          of the area in 2021. The reasonable expectation of Club Members (data subjects) that they
          and their families are fully protected inside the Club premises has also been duly
          considered. Having assessed all the relevant factors, the Club has concluded that the
          existing video surveillance system continues to be necessary to ensure the desired level
          of security and protection of persons and goods, which cannot be achieved by lesser means.
        </li>
        <li>
          In order to ensure the protection of privacy, our company has made sure that the video
          surveillance system does not record or process images which reveal special categories of
          personal data. The video surveillance cameras are essentially fixed and do not transmit
          information over the internet.
        </li>
      </ul>
      <p>
        <strong>Video surveillance areas</strong>
      </p>
      <ul>
        <li>
          Monitored areas are covered by cameras, with or without motion recording. They include the
          entrances and exits of the Club as well as areas within the Club premises. They have been
          carefully installed in suitable locations by professional experts, so as to ensure that
          surveillance is limited to those areas that are absolutely essential in connection with
          the surveillance purposes.
        </li>
        <li>
          Surveillance in indoor areas is kept to an absolute minimum and does not include images
          from changing rooms or entrances to toilets.
        </li>
        <li>
          The system is not used for the surveillance and evaluation of staff within offices and
          other working areas.
        </li>
        <li>
          The operation of the cameras during working and non-working hours ensures the security of
          the facilities and infrastructure (e.g. for the prevention of criminal offences, early
          detection of malicious acts, early warning for flooding or fires), especially overnight
          and during holidays, when these risks are increased.
        </li>
      </ul>
      <p>
        <strong>Video surveillance data</strong>
      </p>
      <ul>
        <li>
          The video surveillance system consists of, where appropriate, recording machines and fixed
          cameras. The system records traffic in the surveillance areas in conjunction with the date
          and time. The images from the cameras operating at all hours are available in real time to
          the security officer of TATOI CLUB.
        </li>
        <li>
          Furthermore, we have installed an access control system at the entrances and exits of our
          facilities whereby access by personnel, Members and visitors is done using a personalized
          access card. According to law 4624/2019, data collected by this access system is not used
          to monitor staff or to evaluate the attitude and performance of employees.
        </li>
      </ul>
      <p>
        <strong>Duration of storage</strong>
      </p>
      <p>
        The storage period of the recorded material is limited to a maximum of fifteen (15) days.
        After this period the material is automatically deleted, unless an exception applies
        (criminal acts, security breaches, requests by police/prosecuting authorities etc.), in
        which case the data is kept separately as evidence material until the criminal procedure is
        completed. In the event of a security-related incident, the relevant recorded material may
        be kept beyond the 15-day period, in accordance with the Guidelines of the Data Protection
        Authority. In particular, the data may be extracted from the system and kept as a separate
        file for up to 45 days. If an incident involves a criminal offence leading to prosecution,
        the relevant images can be stored for up to 3 months. Access to video surveillance data is
        strictly limited to a small number of authorized and specially trained operators.
      </p>
      <p>
        <strong>Video surveillance data protection</strong>
      </p>
      <ul>
        <li>
          The machines connected to the cameras of the video surveillance system have been installed
          in controlled areas with limited access, and are connected to a network which is protected
          from unauthorized access. Access to the surveillance system and the personal data it
          collects, including real time images and recorded material, is limited to a small number
          of authorized individuals.
        </li>
        <li>
          TATOI CLUB allocates access rights according to specific working roles, in order to ensure
          that system operators only see information relevant to their tasks. We use external
          partners / data processors who are responsible for the safety and security of our
          facilities, in order to ensure the protection of persons and goods (monitoring of internal
          circuits, visitors control, supervision of offices and facilities, basic access control,
          fire safety and fire extinguishing equipment control).
        </li>
      </ul>
      <p>
        <strong>External processors</strong>
      </p>
      <ul>
        <li>
          The processing of personal data obtained via the video surveillance system is based on
          online technical support. It also includes regular controls of the internal and external
          system circuits by sub-contractors and installation/maintenance staff, as well as backup
          copies of the surveillance and recording software. These external partners are bound by a
          written contract with us to ensure the confidentiality and security of the processing.
        </li>
        <li>
          All system operators are aware of the purpose of processing and the permissible uses of
          the system. Apart from the limited number of authorized staff members who have access to
          the video surveillance data due to their work role, this data is not made available or
          shared with third parties, unless the persons shown in the relevant files have given their
          consent. An exception to this restriction is where the competent judicial, prosecutorial
          and police authorities request access to data in order to perform their duties in the
          prosecution of crimes against persons or property. Furthermore, under the conditions laid
          down in national legislation, it is permitted to transfer such data to the persons who are
          depicted in these records as either victims or perpetrators of a criminal act.
        </li>
        <li>
          Our company keeps a strict system of data breach management and documentation, in close
          cooperation with our Data Protection Officer.
        </li>
      </ul>
      <p>
        <strong>Rights of subjects</strong>
      </p>
      <p>Data subjects have the following rights:</p>
      <ul>
        <li>
          Right to access: you have the right to know if we are processing your image, and if so, to
          request a copy if that is technically and legally feasible.
        </li>
        <li>
          Right to restriction of processing: you have the right to request that we restrict
          processing of your data, for example to not delete data which you considered necessary to
          establish, exercise or support a legal claim.
        </li>
        <li>
          Right to object: you have the right to object to the processing of your data, unless other
          overriding compelling and legitimate grounds apply.
        </li>
        <li>
          Right to erasure: you have the right to request the erasure of your data, e.g. when such
          data is no longer necessary in relation to the purposes for which it was collected and
          stored, or if you have exercised the right to object and there are no overriding
          legitimate grounds for the processing.
        </li>
      </ul>
      <p>
        You may exercise your rights by sending an email to{' '}
        <a href="mailto:pp@paschakislaw.com">pp@paschakislaw.com</a> or a letter to our postal
        address, or by submitting your request in person. To examine a request regarding the use of
        your image, you will need to give us an approximate time when you were within range of the
        cameras, and provide us with an image sample of yourself, to enable us to locate and give
        you the data that concerns you without revealing the data of any third persons who might
        also be depicted. Alternatively, we can arrange for you to visit our premises for a
        demonstration of the footage where your image appears. Please note that the right to object
        and the right to erasure do not entail the immediate deletion of data or modification of the
        processing.
      </p>
      <p>
        We always try to respond to all requests in detail as soon as possible, and in any case
        within the deadlines laid down in the GDPR. Should you consider that the processing of your
        data does not comply with Regulation (EE) 2016/679, you have the right to file a complaint
        with the competent authority. In Greece, the competent authority is the Data Protection
        Authority located at Kifissias 1-3, Athens 11523,{' '}
        <a href="http://www.dpa.gr" target="_blank">
          http://www.dpa.gr
        </a>
        , tel: +30 210 647 5600.
      </p>
      <p>
        The company periodically reviews this policy to reflect its principles and practices. Last
        update: 10.04.2023.
      </p>
    </div>
  );
};

export default CameraPolicy;
