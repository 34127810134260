import React, { Fragment } from 'react';
import SvgRender from 'components/general/SvgRender';
import Input from 'components/inputs/Input';
import Radio from 'components/inputs/Radio';
import { Button } from 'reactstrap';
import Checkbox from 'components/inputs/Checkbox';

import stepsNextSvg from 'assets/svg/common/steps-next.svg';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getInitialValues } from 'utils/helpers';
import { post } from 'utils/api';

const Step1 = ({ nextStep, stepValues, setActivePanel }) => {
  const _initialValues = {
    firstname: '',
    lastname: '',
    birthday: '',
    marital_status: true,
    address: '',
    phone: '',
    email: '',
    terms_accepted: false
  };
  const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
  const initialValues = stepValues ? getInitialValues(_initialValues, stepValues) : _initialValues;

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required('*Required'),
          lastname: Yup.string().required('*Required'),
          birthday: Yup.string().required('*Required'),
          email: Yup.string().email('*This email is invalid').required('*Required'),

          phone: Yup.string().matches(phoneRegExp, '*This number is invalid').required('*Required'),

          address: Yup.string().required('*Required'),

          terms_accepted: Yup.bool().oneOf([true], '*Required')
        })}
        onSubmit={async (values, { setErrors, setStatus, ...actions }) => {
          var formValues = {
            ...stepValues,
            ...values
          };

          try {
            await post('/contact/membership', formValues).then(res => {
              if (res.status === 200 && res.data.msg === 'request_submitted') {
                nextStep(formValues);
              } else {
                nextStep({ ...formValues, error: true });
              }
            });
          } catch (error) {
            nextStep({ ...formValues, error: true });
          }
        }}
        render={({
          values,
          errors,
          status,
          setFieldValue,
          handleChange,
          isSubmitting,
          submitCount
        }) => {
          return (
            <Form className="h-100">
              <div className="form-wrapper max-width-480">
                <div className="form-wrapper--inputs form-wrapper--inputs-no-spacing">
                  <Input
                    key="firstname"
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    error={submitCount > 0 && errors.firstname}
                    status={status && status.firstname}
                    onChange={handleChange}
                    value={values.firstname}
                  />

                  <Input
                    key="lastname"
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    error={submitCount > 0 && errors.lastname}
                    status={status && status.lastname}
                    onChange={handleChange}
                    value={values.lastname}
                  />

                  <Input
                    key="birthday"
                    type="text"
                    name="birthday"
                    placeholder="Date of birth"
                    error={submitCount > 0 && errors.birthday}
                    onChange={handleChange}
                    value={values.birthday}
                  />

                  <div className="mb-2 mb-md-4 marital-status-spacing">
                    <div className="mb-2 radio-label">Marital Status</div>
                    <div className="d-flex align-items-center marital-status-container">
                      <Radio
                        checked={values.marital_status}
                        name={'marital_status'}
                        label="Married"
                        className="mr-4"
                        onChange={() => {
                          setFieldValue('marital_status', true);
                        }}
                      />
                      <Radio
                        checked={!values.marital_status}
                        name={'marital_status'}
                        label="Unmarried"
                        onChange={() => {
                          setFieldValue('marital_status', false);
                        }}
                      />
                    </div>
                  </div>

                  <Input
                    key="address"
                    type="address"
                    name="address"
                    placeholder="Address"
                    error={submitCount > 0 && errors.address}
                    status={status && status.address}
                    onChange={handleChange}
                    value={values.address}
                  />

                  <Input
                    key="phone"
                    type="tel"
                    name="phone"
                    placeholder="Mobile phone"
                    error={submitCount > 0 && errors.phone}
                    status={status && status.phone}
                    onChange={handleChange}
                    value={values.phone}
                  />

                  <Input
                    key="email"
                    type="text"
                    name="email"
                    placeholder="Email"
                    error={submitCount > 0 && errors.email}
                    status={status && status.email}
                    onChange={handleChange}
                    value={values.email}
                  />

                  <div className="d-flex checkbox-container">
                    <Checkbox
                      name={'terms_accepted'}
                      value={values.terms_accepted}
                      id={'terms_accepted'}
                      handleClick={() => setFieldValue('terms_accepted', !values.terms_accepted)}
                    />
                    <div className="col mb-0 terms-accepted-error-text font-12 font-md-14">
                      I have read & I accept the{' '}
                      <strong
                        className="pointer font-weight-bold"
                        onClick={() => setActivePanel('legal_terms')}
                      >
                        <u>Terms of Use</u>
                      </strong>{' '}
                      &{' '}
                      <strong
                        className="pointer font-weight-bold"
                        onClick={() => setActivePanel('legal')}
                      >
                        <u>Privacy Policy</u>.
                      </strong>
                      {errors.terms_accepted ? (
                        <div className="terms-accepted-error form-error">
                          {errors.terms_accepted}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-wrapper--actions d-flex justify-content-end">
                  <Button
                    color="link"
                    className="d-flex align-items-center p-0 font-weight-bold btn-with-arrow"
                    type="submit"
                  >
                    <span className="pr-1 ">Submit</span>
                    <SvgRender style={{ height: 20, width: 8 }} src={stepsNextSvg} />
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    </Fragment>
  );
};

export default Step1;
