import React, { useState, Fragment } from 'react';

const Cover = ({ cover, coverType, coverStyle, coverMobile }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div className="cover">
      {coverType == 'video' ? (
        <video
          playsInline={true}
          style={coverStyle}
          id="video"
          muted={true}
          key={coverType}
          className={`h-100 ${loaded ? 'loaded' : ''}`}
          loop
          autoPlay="autoplay"
          onLoadedMetadata={() => setLoaded(true)}
        >
          <source src={cover} type="video/mp4" />
        </video>
      ) : coverMobile ? (
        <Fragment>
          <img
            className={`d-sm-none ${loaded ? 'loaded' : ''}`}
            src={coverMobile}
            onLoad={() => setLoaded(true)}
            onError={() => setLoaded(true)}
          />
          <img
            src={cover}
            className={`d-none d-sm-block ${loaded ? 'loaded' : ''}`}
            onLoad={() => setLoaded(true)}
            onError={() => setLoaded(true)}
            style={coverStyle}
          />
        </Fragment>
      ) : (
        <img src={cover} style={coverStyle} />
      )}
    </div>
  );
};

export default Cover;
