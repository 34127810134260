import React, { useState, useEffect, Fragment } from 'react';
import Select from 'components/inputs/Select';

const Tabs = ({
  tabs,
  dropdown,
  disableDropdown = false,
  initialIndex,
  mobileView,
  handleTabClick
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (initialIndex !== undefined && initialIndex != activeTabIndex) {
      setActiveTabIndex(initialIndex);
    }
  }, [initialIndex]);

  const rendeDropdown = () => {
    const active = tabs.find((t, index) => index == activeTabIndex);
    const activeTabs = tabs.filter(e => !e.disabled);

    return (
      <Select
        options={activeTabs}
        value={active}
        isSearchable={false}
        className="tabs-drodown mb-2 mb-md-10"
        getOptionLabel={option => option.button}
        getOptionValue={option => option.button}
        name="registration_type"
        onChange={e => {
          const selectedIndex = tabs.findIndex(t => t.button == e.button);
          setActiveTabIndex(selectedIndex);
        }}
        label={''}
        isDisabled={disableDropdown}
      />
    );
  };

  const renderButtons = () => {
    return (
      <Fragment>
        {dropdown && rendeDropdown()}
        <div className="tabs-buttons">
          {tabs.map((t, index) => (
            <div
              className={`${index === activeTabIndex ? 'active' : ''} ${
                t.disabled ? 'disabled' : ''
              }`}
              onClick={() => {
                setActiveTabIndex(index);
                if (handleTabClick) handleTabClick(index);
              }}
              key={index}
            >
              <span>{t.button}</span>
            </div>
          ))}
        </div>
      </Fragment>
    );
  };

  const renderActiveContent = () => {
    const ActiveTabContent = tabs[activeTabIndex].content;

    return (
      <div className="tab-content scrollbar">
        <ActiveTabContent />
        <div className={`${mobileView ? '' : 'opacity-gradient'}`} />
      </div>
    );
  };

  return (
    <div className={`tabs ${dropdown ? 'has-dropdown' : ''}`}>
      {renderButtons()}
      {renderActiveContent()}
    </div>
  );
};

export default Tabs;
