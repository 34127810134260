import React from 'react';
import Steps from 'components/general/Steps';
import Step1 from './Step1';
import Step5 from './Step5';

const JoinUs = ({ setActivePanel }) => {
  return (
    <div className="menu-panel menu-panel--join-us" style={{ visibility: 'hidden' }}>
      <div className="menu-panel-title">JOIN US</div>
      <Steps
        steps={steps}
        setActivePanel={setActivePanel}
        hideStepsIndicator
        hideLastStepIndicator
      />
    </div>
  );
};

const steps = [
  {
    component: Step1
  },
  {
    component: Step5
  }
];

export default JoinUs;
