import React, { Component } from 'react';

import animationData from 'assets/svg/animations/back-top.json';
import Lottie from 'react-lottie';

class ScrollButton extends Component {
  render() {
    return (
      <div
        onClick={() =>
          document.querySelector('.app').scroll({ top: 0, left: 0, behavior: 'smooth' })
        }
        className="position-absolute btn-scroll-top"
      >
        <Lottie
          ref={el => (this.el = el)}
          height={80}
          options={{
            animationData
          }}
        />
      </div>
    );
  }
}

export default ScrollButton;
