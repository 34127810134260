import React, { useState } from 'react';
import cookies from 'utils/cookie';

const CookiesPolicy = ({ setMenuIsOpen, setActivePanel, setActiveIndex }) => {
  const [accepted, setAccepted] = useState(false);

  const policy = cookies.get('accepted-policy');
  if (policy && !accepted) {
    setAccepted(true);
  }

  return (
    <div
      className={`cookies-policy align-items-center position-fixed w-100 no-gutters px-2 px-md-4 px-lg-8 row ${
        accepted ? 'accepted' : ''
      }`}
    >
      <div className="col">
        This website uses necessary cookies. We also use cookies to offer you a better browsing
        experience and personalized services. If you want to read more, you can consult our{' '}
        <button
          color="link"
          onClick={() => {
            setMenuIsOpen(true);

            setTimeout(() => {
              setActivePanel('legal');
              setActiveIndex(2);
            }, 10);
          }}
          className="btn pl-0 py-0"
        >
          Cookies Policy.
        </button>
      </div>
      <div className="col-auto ml-2">
        <button
          onClick={() => {
            cookies.set('accepted-policy', true);
            setAccepted(true);
          }}
          className="btn btn-outline-primary btn-accept font-12"
        >
          I agree
        </button>
      </div>
    </div>
  );
};

export default CookiesPolicy;
