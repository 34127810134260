import React, { Fragment, useState, useRef } from 'react';
import PrimarySection from 'components/page/PrimarySection';
import SecondarySection from 'components/page/SecondarySection';
import SectionsHeader from 'components/page/SectionsHeader';

import culture from 'assets/images/train-the-mind/culture.jpg';

import naturePrimary from 'assets/images/train-the-mind/nature-primary.jpg';
import natureSecondary from 'assets/images/train-the-mind/nature-secondary.jpg';

import culturePrimary from 'assets/images/train-the-mind/culture-primary.jpg';
import cultureSecondary from 'assets/images/train-the-mind/culture-secondary.jpg';

import seminarsPrimary from 'assets/images/train-the-mind/seminars-primary.jpg';
import seminarsSecondary from 'assets/images/train-the-mind/seminars-secondary.jpg';

import ScrollButton from 'components/general/ScrollButton';
const TrainMind = () => {
  return (
    <Fragment>
      <PrimarySection
        title="CREATIVE HEALTH"
        text="The pursuit of continuous growth and balance."
        image={culture}
        leftText={'Train the Mind'}
      />
      <SectionsHeader header="Components of Wellness" animate />
      <SecondarySection
        title="NATURE"
        text="Access to nature improves well-being and here it is generously offered to  Members. Amazing landscapes filled with Mediterranean plants, herbs and vegetables are provided for  relaxing walks or bike rides."
        primaryImage={naturePrimary}
        secondaryImage={natureSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
        className="nature"
      />
      <SecondarySection
        className="culture"
        title="CULTURE"
        text="Intellectual growth and cultural pursuits are also essential components of wellness. Private tours of museum exhibitions are organized throughout the year, as well as inspirational lectures and discussions, which give  Members the unique opportunity to meet and interact with great personalities from the scientific, cultural or academic world."
        primaryImage={culturePrimary}
        secondaryImage={cultureSecondary}
        primaryImageAtRight
        animateSection={true}
        alignRight
        offsetLeft={76}
        offsetRight={76}
      />
      <SecondarySection
        title="SEMINARS & TALKS"
        text="From calligraphy and photography to watercolor lessons,  Members  also have the chance to participate in interesting seminars, aiming at fostering creativity and social interaction."
        primaryImage={seminarsPrimary}
        secondaryImage={seminarsSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
        className="seminars"
      />

      <ScrollButton />
    </Fragment>
  );
};

export default TrainMind;
