const Cookie = {
  set: function(name, value, days, subdomainOnly) {
    var domain, domainParts, date, expires, host;

    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    } else {
      expires = '';
    }

    domain = '';
    host = window.location.host;
    domainParts = host.split('.');

    if (!subdomainOnly) {
      // Store cross subdomains cookie

      domain = !domainParts.some(
        d => d === `localhost${window.location.port ? ':' + window.location.port : ''}`
      )
        ? '.' + domainParts[domainParts.length - 2] + '.' + domainParts[domainParts.length - 1]
        : 'localhost';

      if (window.location.port) {
        domain = domain.replace(`:${window.location.port}`, '');
      }

      document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain;
    } else if (subdomainOnly && host.split('.').length !== 1) {
      // Will store the cookie only for the subdomain

      domainParts.shift();
      domain = '.' + domainParts.join('.');

      document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain;

      // check if cookie was successfuly set to the given domain
      // (otherwise it was a Top-Level Domain)
      if (Cookie.get(name) === null || Cookie.get(name) !== value) {
        // append "." to current domain
        domain = '.' + host;

        if (window.location.port) {
          domain = domain.replace(`:${window.location.port}`, '');
        }

        document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain;
      }
    }
  },
  get: function(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  erase: function(name) {
    Cookie.set(name, '', -1);
  }
};

export default Cookie;
