import React, { Fragment } from 'react';
import SvgRender from 'components/general/SvgRender';
import { UncontrolledTooltip } from 'reactstrap';

import careerSvg from 'assets/svg/menu/career.svg';
import legalSvg from 'assets/svg/menu/legal.svg';
import instagramSvg from 'assets/svg/menu/instagram.svg';
import joinUsSvg from 'assets/svg/menu/join_us.svg';
import youtubeSvg from 'assets/svg/menu/youtube.svg';
import t_home from 'assets/svg/menu/t_home.svg';

const MenuActions = ({ activePanel, setActivePanel, setIsFlipped }) => {
  const handleActionClick = panel => {
    if (activePanel === panel) {
      setIsFlipped(false);
      setTimeout(() => {
        setActivePanel('');
      }, 300);
    } else {
      setActivePanel(panel);
    }
  };

  //tooltip causes double clicks required on mobile device
  const isTouchDevice = () => {
    return (
      true ==
      ('ontouchstart' in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch))
    );
  };

  return (
    <div className="menu-bottom">
      <ul className="menu-actions reset-list">
        {actions.map(action => {
          const { key, icon, tooltip, link, size } = action;

          return (
            <li
              onClick={() => (!link ? handleActionClick(key) : null)}
              className={`action cursor-pointer ${
                activePanel && activePanel !== key ? 'text-primary-fade' : ''
              } ${key === 'about_us' ? 'pr-sm-2' : ''}`}
              key={key}
            >
              {link ? (
                <a
                  href={link}
                  className={`${activePanel && activePanel !== key ? 'text-primary-fade' : ''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SvgRender src={icon} id={key} style={{ width: size ? size : null }} />
                  {!isTouchDevice() ? (
                    <UncontrolledTooltip
                      placement="top"
                      target={key}
                      delay={{ show: 0, hide: 0 }}
                      fade
                    >
                      {tooltip}
                    </UncontrolledTooltip>
                  ) : null}
                </a>
              ) : (
                <Fragment>
                  <SvgRender src={icon} id={key} style={{ width: size ? size : null }} />
                  {!isTouchDevice() ? (
                    <UncontrolledTooltip
                      placement="top"
                      target={key}
                      delay={{ show: 0, hide: 0 }}
                      fade
                    >
                      {tooltip}
                    </UncontrolledTooltip>
                  ) : null}
                </Fragment>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const actions = [
  {
    key: 'about_us',
    icon: t_home,
    tooltip: 'ABOUT US',
    size: 14
  },
  // {
  //   key: 'join_us',
  //   icon: joinUsSvg,
  //   tooltip: 'JOIN US'
  // },

  {
    key: 'career',
    icon: careerSvg,
    tooltip: 'CAREER'
  },

  {
    key: 'legal',
    icon: legalSvg,
    tooltip: 'LEGAL'
  },
  {
    key: 'instagram',
    icon: instagramSvg,
    tooltip: 'INSTAGRAM',
    link: 'https://www.instagram.com/tatoiclub/'
  },
  {
    key: 'youtube',
    icon: youtubeSvg,
    tooltip: 'YOUTUBE',
    link: 'https://m.youtube.com/channel/UCIjHsT18OKr7Qs973Et05Eg'
  }
];

export default MenuActions;
