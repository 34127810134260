import React from 'react';
import { Button } from 'reactstrap';

const Step5 = ({ previousStep, nextStep, stepValues }) => {
  return (
    <div className="d-flex flex-column justify-content-center flex-fill">
      <div className="font-22 mb-4 line-height-42">
        {stepValues.error ? (
          <div>
            Your form is not
            <br />
            submitted successfully.
          </div>
        ) : (
          <div>
            Thanks for filling out <br />
            our form!
          </div>
        )}
      </div>
      <div className="text-primary-fade">
        {stepValues.error ? (
          <div>
            Please
            <Button
              color="link"
              className="text-blue"
              onClick={() => {
                previousStep(stepValues);
              }}
            >
              Try again.
            </Button>
          </div>
        ) : (
          `We’ll get back to you shortly.`
        )}
      </div>
    </div>
  );
};

export default Step5;
