import React from 'react';

const Slide = ({
  slide: { location, time, event, image, tabletImage, mobileImage, label, className }
}) => {
  return (
    <div className="slide-inner">
      <div className="slide-inner--info">
        <div className="slide-inner--info--label font-12 font-md-14 font-weight-medium mb-1">
          LOCATION
        </div>
        <div className="slide-inner--info--value font-10 font-md-14 font-weight-medium">
          {location}
        </div>
        <div className="slide-inner--info--divider" />
        <div className="font-12">
          {time} <br />
          {event}
        </div>
      </div>
      <img
        alt="slide"
        className={`slide-inner--image d-none d-md-block web-image ${className || ''}`}
        src={image}
      />
      <img
        alt="slide"
        className={`slide-inner--image d-none d-sm-block d-md-none tablet-image ${className || ''}`}
        src={tabletImage}
      />
      <img
        alt="slide"
        className={`slide-inner--image d-sm-none mobile-image ${className || ''}`}
        src={mobileImage}
      />

      <div className="slide-inner--label narrow-500 done">{label}</div>
    </div>
  );
};

export default Slide;
