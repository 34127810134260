import React, { Fragment } from 'react';
import PrimarySection from 'components/page/PrimarySection';
import SecondarySection from 'components/page/SecondarySection';
import SectionsHeader from 'components/page/SectionsHeader';

import experiance from 'assets/images/eat-well/experience.jpg';

import tablePrimary from 'assets/images/eat-well/table-primary.jpg';
import tableSecondary from 'assets/images/eat-well/table-secondary.jpg';

import indoorPrimary from 'assets/images/eat-well/indoor-outdoor-primary.jpg';
import salad from 'assets/images/eat-well/salad.jpeg';
import indoorSecondary from 'assets/images/eat-well/indoor-secondary.jpg';
import tables from 'assets/images/eat-well/tables.jpg';

import cookhousePrimary from 'assets/images/eat-well/cookhouse-primary.jpg';
import cookhouseSecondary from 'assets/images/eat-well/cookhouse-secondary.jpg';

import ScrollButton from 'components/general/ScrollButton';
const EatWell = () => {
  const primaryImages = [indoorPrimary, salad];
  const secondaryImages = [indoorSecondary, tables];

  return (
    <Fragment>
      <PrimarySection
        title="GASTRONOMIC EXPERIENCE"
        text="A better way of eating is a step towards a better way of living."
        image={experiance}
        leftText={'Eat Well'}
      />
      <SectionsHeader header="Balanced Nutrition" animate />
      <SecondarySection
        title="FROM FARM TO TABLE"
        text="Our philosophy is demonstrated by balanced, healthy and delicious options, inspired by nature and lovingly cooked in-house with seasonal, farm to table ingredients which are sourced from local farmers as well as our own gardens."
        primaryImage={tablePrimary}
        secondaryImage={tableSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
        className="from-farm-to-table"
      />

      <SecondarySection
        className="indoor-and-outdoor-dining"
        title="INDOOR & OUTDOOR DINING"
        text="The Club’s Bar Restaurant is a meeting point throughout the day.  Its indoor spaces reflect warmth and comfort, while its outdoor areas create a beautiful setting surrounded by nature. During summer, the magical ambiance of the two swimming pools becomes the ideal spot for enjoying relaxing and carefree moments accompanied by healthy snacks and refreshing drinks."
        primaryImages={primaryImages}
        secondaryImages={secondaryImages}
        primaryImageAtRight
        animateSection={true}
        alignRight
        hasSlides={true}
        slideImages
        offsetLeft={76}
        offsetRight={76}
      />
      <SecondarySection
        title="THE COOKHOUSE"
        text="At the fully equipped kitchen of the Cook House,  Members can learn the secrets of healthy eating through inspirational cooking workshops with fresh ingredients from the adjoining Herb Garden."
        primaryImage={cookhousePrimary}
        secondaryImage={cookhouseSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
        className="the-cookhouse"
      />

      <ScrollButton />
    </Fragment>
  );
};

export default EatWell;
