import React, { useState } from 'react';

const Steps = ({
  steps,
  hideLastStepIndicator = false,
  hideStepsIndicator = false,
  className,
  wrapperClassName = '',
  setActivePanel
}) => {
  const [values, setSteps] = useState({ activeStepNum: 0, stepValues: null });

  const activeStep = steps[values.activeStepNum];
  const activeStepNum = values.activeStepNum;
  const renderIndicators = () => {
    return (
      <div className="steps-indicators mb-2 mb-sm-3 mb-md-4 md-xl-10">
        {steps.map((step, index) => {
          if (index === steps.length - 1 && hideLastStepIndicator) return null;
          return <div key={index} className={`${activeStepNum === index ? 'active' : ''}`} />;
        })}
      </div>
    );
  };

  return (
    <div className={`steps ${wrapperClassName}`}>
      {hideStepsIndicator
        ? ''
        : activeStepNum === steps.length - 1 && hideLastStepIndicator
        ? null
        : renderIndicators()}

      <div className={`step-main ${className || ''}`}>
        {
          <activeStep.component
            stepValues={values.stepValues}
            previousStep={value => {
              setSteps({
                activeStepNum: activeStepNum - 1,
                stepValues: { ...values.stepValues, ...value }
              });
            }}
            nextStep={value => {
              setSteps({
                activeStepNum: activeStepNum + 1,
                stepValues: { ...values.stepValues, ...value }
              });
            }}
            setActivePanel={setActivePanel}
          />
        }
      </div>
    </div>
  );
};

export default Steps;
