import React from 'react';

import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div>
      <p>
        <strong>Welcome to our website.</strong>
      </p>
      <p>
        The «<strong>TATOI CLUB</strong>» (hereinafter referred “ Club” ) provides website features
        and other services to you when you visit its website, join as a guest or as a member, use
        services, use applications for mobile, or use software provided by the Club in connection
        with any of the foregoing (hereinafter referred “Services”).
      </p>
      <p>The Club provides the Services subject to the following conditions.</p>
      <p>
        Please read these Terms of Use carefully before using the Club’s Website and the Club’s
        Mobile App Services.
      </p>
      <p>
        Your access to and use of the Services is conditioned on your acceptance of and compliance
        with these Terms and with the membership regulation accepted by you when you become our
        member.
      </p>
      <p>
        By accessing the Club’s website or by using it’s Services, you agree to be bound by these
        terms of use. If you do not agree to be bound by all of these Terms of Use, you may not
        access or use the Services.
      </p>
      <p>
        The Club reserves the right to update and change these terms from time to time without
        previous notice.
      </p>
      <p>
        The Club may terminate or suspend access to it’s Services immediately, without prior notice
        or liability, for any reason whatsoever, including without limitation if you breach the
        Terms.
      </p>
      <p>
        The Website’s and its original content, features, and functionality are owned by The Club
        and are protected by international, European and Greek copyright, trademark, patent, trade
        secret and other intellectual property or proprietary rights laws.
      </p>
      <p>
        The Club’s Services allow you to post, link, store, share and otherwise make available
        certain information, text, graphics, videos, or other material ("Content"). You represent,
        warrant, and agree that no Content posted or otherwise shared by you on or through any of
        the Services , violates or infringes upon the rights of any third party, including
        copyright, trademark, privacy, publicity, or other personal or proprietary rights, breaches
        or conflicts with any obligation, such as a confidentiality obligation, or contains
        libelous, defamatory, or otherwise unlawful material.
      </p>
      <p>
        You agree to (a) only provide accurate and current information about yourself (b) maintain
        the security of your passwords and identification, (c) promptly update the email address
        listed in connection with your account to keep it accurate so that we can contact you, and
        (d) be fully responsible for all uses of your account. You must not set up an account on
        behalf of another individual or entity unless you are authorized to do so.
      </p>
      <p>You agree not to engage in any of the following activities:</p>
      <ul>
        <li>
          <span>
            You may not (a) use any Service for any illegal purpose or in violation of any national
            , European and international laws, (b) violate or encourage others to violate any right
            of or obligation to a third party, including by infringing, misappropriating, or
            violating intellectual property, confidentiality, or privacy rights.
          </span>
        </li>
        <li>
          <span>
            You may not use the Services or any information provided through the Services for the
            transmission of advertising or promotional materials, including junk mail, spam, chain
            letters, pyramid schemes, or any other form of unsolicited or unwelcome solicitation.
          </span>
        </li>
        <li>
          <span>
            You may not use the Services in any manner that could disable, overburden, damage, or
            impair the Services, or interfere with any other party’s use and enjoyment of the
            Services; including by (a) uploading or otherwise disseminating any virus, adware,
            spyware, worm or other malicious code, or (b) interfering with or disrupting any
            network, equipment, or server connected to or used to provide any of the Services, or
            violating any regulation, policy, or procedure of any network, equipment, or server.
          </span>
        </li>
        <li>
          <span>
            You may not post or transmit Content on or through the Services that is harmful,
            offensive, obscene, abusive, invasive of privacy, defamatory, hateful or otherwise
            discriminatory, false or misleading, or incites an illegal act;
          </span>
        </li>
        <li>
          <span>You may not intimidate or harass another through the Services</span>
        </li>
        <li>
          <span>
            You may not impersonate another person or entity, or misrepresent your affiliation with
            a person or entity when using the Services
          </span>
        </li>
        <li>
          <span>
            You may not use or attempt to use another’s account or personal information without
            authorization
          </span>
        </li>
        <li>
          <span>
            You may not attempt to gain unauthorized access to the Services, or the computer systems
            or networks connected to the Services, through hacking password mining or any other
            means.
          </span>
        </li>
      </ul>
      <p>
        The Club’s Services may contain links to third-party web sites or services that are not
        owned or controlled by the Club.
      </p>
      <p>
        The club has no control over, and assumes no responsibility for, the content, or practices
        of any third party web sites or services. You further acknowledge and agree that the Club
        shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
        alleged to be caused by or in connection with use of or reliance on any such content, goods
        or services available on or through any such web sites or services.
      </p>
      <p>
        To the fullest extend permitted by applicable law ,the Club is not responsible or liable
        whatsoever in any manner for any content posted on or available through the services (
        including claims of infringement relating to that content ) for your use of the Services of
        for the conduct of third parties on or through the Services.
      </p>
      <p>
        The Club is committed to responsibly handling the information and data it collects through
        it’s Services in compliance with it’s 
        <Link to="/legal/privacy-policy">Privacy Policy</Link>. Please review the Privacy Policy, so
        you are aware of how we collect and use your personal information.
      </p>
      <p>
        If any part of the Terms is held to be invalid or unenforceable by any law or regulation or
        final determination of a competent court or tribunal, that provision will be deemed
        severable and will not affect the validity and enforceability of the remaining provisions.
      </p>
      <p>
        The parties agree that any disputes between the Club and you concerning these Terms, and/or
        any of the Services may only brought in the Courts of Athens.
      </p>
    </div>
  );
};

export default Terms;
