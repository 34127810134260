import React, { Component } from 'react';

export default class Radio extends Component {
  render() {
    const { name, className, label, disabled, checked, value, onChange, labelAbove } = this.props;

    return (
      <label
        className={`radio-wrapper  
          ${className}
          ${disabled ? 'disabled' : ''}
          ${labelAbove ? 'label-above' : ''}
        `}
      >
        <input type="radio" name={name} checked={checked} value={value} onChange={onChange} />
        <span className="checkmark" />
        <span>{this.props.label}</span>
      </label>
    );
  }
}
