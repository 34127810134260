import React, { useState, useRef } from 'react';
import Typist from 'react-typist';

import { useInsertionToViewportCallback } from 'utils/hooks';

const SectionsHeader = ({ header, typist, animate }) => {
  const sectionRef = useRef(null);
  const [showHeader, setShowHeader] = useState(false);

  const insertionCallbacks = [
    {
      insertion: 200,
      callback: () => setShowHeader(true),
      hasDone: showHeader
    }
  ];

  useInsertionToViewportCallback(sectionRef, insertionCallbacks);

  const renderWords = () => {
    const words = header.split(' ');

    return (
      <div className="sections-header--animated">
        <div className="sections-header--animated-first">
          <h2>{words[0]}</h2>
        </div>
        {words[1] && (
          <div className="sections-header--animated-rest">
            <h2>{header.slice(words[0].length + 1, header.length)}</h2>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="sections-header narrow-700" ref={sectionRef}>
      {showHeader &&
        (typist ? (
          <Typist cursor={{ show: false }} avgTypingDelay={30}>
            {header}
          </Typist>
        ) : animate ? (
          renderWords()
        ) : (
          <div>{header}</div>
        ))}
    </div>
  );
};

export default SectionsHeader;
