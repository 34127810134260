import BeActive from 'views/be-active';
import BeOurQuest from 'views/be-our-guest';
import EatingWell from 'views/eating-well';
import Invigorous from 'views/invigorous';
import Landing from 'views/landing';
import TrainTheMing from 'views/train-the-mind';

const routes = [
  {
    path: '/',
    component: Landing
  },
  {
    path: '/be-active/:active(tennis|fitness)?',
    component: BeActive
  },
  {
    path: '/be-our-guest',
    component: BeOurQuest
  },
  {
    path: '/eating-well',
    component: EatingWell
  },
  {
    path: '/be-vigorous',
    component: Invigorous
  },
  {
    path: '/train-the-mind',
    component: TrainTheMing
  },
  {
    path: '/legal/:tab(privacy-policy|terms-and-conditions|cookies-policy|camera-policy)',
    component: Landing
  }
];

export default routes;
