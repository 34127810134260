import React from 'react';

const PartOfTact = () => {
  return (
    <div className="menu-panel part-of-tact overflow-hidden h-100">
      <div className="menu-panel-title">BE SUSTAINABLE</div>
      <div className="h-100 pb-5 pb-xl-10 about-us-wrapper">
        <div className="about-us-scrollable">
          <div className="font-weight-light mb-2 mt-xl-8 text-primary menu-panel-philosophy">
            <p>
              T·ACT is an extension of our philosophy and represents an act of integrity. A
              collective effort that aims to make a difference by improving the wellbeing of our
              local communities.
            </p>
          </div>
          <div className="font-12 font-md-14 font-weight-bold menu-panel-subtitle">OUR GOALS:</div>
          <div className="font-weight-light second-text mb-4 text-primary menu-panel-measure-impact">
            <p>We want to measure the impact of our everyday activities on the pillars of</p>
            <ul>
              <li>Environment</li>
              <li>Society</li>
            </ul>
            <p>
              In doing so, we will be able to improve our practices and have a more positive impact
              on the world while adhering to our values.
            </p>
          </div>

          <div className="contact-info d-flex mt-xl-9 justify-content-center">
            <div className="about-us pr-6 pr-md-10">
              <div className="menu-panel-title">BE A VITAL PART</div>

              <p className="line-height-16">T: +30 214 416 6000</p>
              <p className="line-height-16">E: tact@tatoiclub.com</p>

              <p className="line-height-16 mt-3 menu-panel-link">
                <a href="http://tact.tatoiclub.com" target="_blank" rel="noopener noreferrer">
                  tact.tatoiclub.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartOfTact;
