const vanillaJsuParallax = settings => {
  'use strict';

  var self = this,
    itemCallback,
    itemPrepare,
    itemPerspective = 0,
    minViewportHeight = 0,
    minViewportWidth = 0,
    activeParallax = 0,
    winHeight = 0,
    winWidth = 0,
    items = [],
    itemsOpts = [],
    callback,
    itemsLen = 0;

  var initFun = function(settings) {
    /* Opts */
    items = settings.items || [];
    itemsLen = items.length;

    itemCallback = settings.itemCallback || itemCallbackDefault;
    callback = settings.callback;
    itemPrepare = settings.itemPrepare || itemPrepareDefault;
    itemPerspective = settings.perspective || 200;
    minViewportHeight = settings.minViewportHeight || minViewportHeight;
    minViewportWidth = settings.minViewportWidth || minViewportWidth;

    /* Prepare items */
    for (var i = 0; i < itemsLen; i++) {
      itemPrepare(i, items[i]);
    }

    /* Monitor position on scroll, resize, and initial state */
    window.addEventListener('resize', scrollEvent, 1);
    document.addEventListener('scroll', scrollEvent, 1);
    scrollEvent();
  };

  /* Scroll Event */
  var scrollEvent = function() {
    var i;
    winHeight = parseInt(window.innerHeight, 10);
    winWidth = parseInt(window.innerWidth, 10);
    if (winWidth >= minViewportWidth && winHeight >= minViewportHeight) {
      for (i = 0; i < itemsLen; i++) {
        setScrollItem(i, items[i]);
      }
    } else {
      if (activeParallax) {
        activeParallax = 0;
        for (i = 0; i < itemsLen; i++) {
          setTransformTranslate3d(items[i], 0, 0, 0);
        }
      }
    }
  };

  var setScrollItem = function(i, item) {
    var rect = item.getBoundingClientRect(),
      elCenter = (rect.top + rect.bottom) / 3,
      distMiddle = elCenter / winHeight - 1 / 2;
    itemCallback(i, item, distMiddle);

    if (callback) {
      callback(item, distMiddle);
    }
  };

  /* Default callbacks */
  var itemPrepareDefault = function(i, item) {
    var opt = {};
    /* Movement direction */
    opt.dir = item.getAttribute('data-jsuplxdir') == 'bottom' ? 'bottom' : 'top';
    /* Movement perspective */
    var elPerspective = parseInt(item.getAttribute('data-y'), 10);
    opt.perspective = elPerspective ? elPerspective : itemPerspective;
    /* Save options */
    itemsOpts[i] = opt;
    /* Initial style */
    if (item) {
      item.style.willChange = 'transform';
    }
    return true;
  };

  var itemCallbackDefault = function(i, item, distMiddle) {
    var dist = distMiddle * itemsOpts[i].perspective;

    if (dist > itemsOpts[i].perspective) return;
    if (itemsOpts[i].dir == 'bottom') {
      dist = 0 - dist;
      //if (dist > 30) return;
    } else if (dist > 20) {
      //return;
    }

    setTransformTranslate3d(item, 0, dist, 0);
  };

  /* Helper */
  var setTransformTranslate3d = function(item, x, y, z) {
    if (!item) {
      return;
    }
    itemStyleSetTransform(item, 'translate3d(' + x + 'px,' + y + 'px,' + z + 'px)');
    if (x != 0 || y != 0 || z != 0) {
      activeParallax = 1;
    }
  };

  var itemStyleSetTransform = function(item, transformString) {
    item.style.webkitTransform = transformString;
    item.style.mozTransform = transformString;
    item.style.transform = transformString;
  };

  initFun(settings);
  return self;
};
export default vanillaJsuParallax;
