import 'assets/scss/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

smoothscroll.polyfill();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <App />
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);
