import React from 'react';

import SinglePage from 'components/page/SinglePage';
import InvigorousPage from './InvigorousPage';

import vigorous from 'assets/videos/vigorous.mp4';

const Invigorous = props => {
  const page = {
    name: 'Be Vigorous',
    abbrev: 'UP',
    cover: vigorous,
    coverType: 'video',
    content: InvigorousPage,
    coverStyle: { objectPosition: 'center right' },
    tabs: [
      {
        button: 'CRYOTHERAPY',
        key: 'cryotherapy'
      },
      {
        button: 'PHYSIOTHERAPY',
        key: 'physiotherapy'
      },
      {
        button: 'SPA',
        key: 'spa'
      },
      {
        button: 'NUTRITION',
        key: 'nutrition'
      }
    ]
  };

  return <SinglePage page={page} title="Be Vigorous | Tatoi Club " />;
};

export default Invigorous;
