import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ placeholder, options, className, value, onChange, label, ...rest }) => {
  return (
    <div className={className}>
      {label && <label className="mr-3">{label}</label>}
      <div className="flex-fill">
        <Select
          className="react-select"
          classNamePrefix="react-select"
          value={value}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </div>
  );
};

export default CustomSelect;
