import React from 'react';
import SinglePage from 'components/page/SinglePage';
import TrainMind from './TrainTheMind';
import cover from 'assets/images/train-the-mind/cover.jpg';
import coverMobile from 'assets/images/train-the-mind/cover-mobile.jpg';

const TrainTheMind = props => {
  const page = {
    name: 'Train the Mind',
    abbrev: 'IQ',
    cover: cover,
    coverMobile,
    content: TrainMind,
    coverStyle: { objectPosition: 'right top' },
    tabs: [
      {
        button: 'NATURE',
        key: 'nature'
      },
      {
        button: 'CULTURE',
        key: 'culture'
      },
      {
        button: 'SEMINARS & TALKS',
        key: 'seminars'
      }
    ]
  };

  return <SinglePage page={page} title="Train the Mind at Tatoi Club" />;
};

export default TrainTheMind;
