import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import MenuLinks from './MenuLinks';
import MenuActions from './MenuActions';
import MenuPanel from './panels';
import CookiesPolicy from './CookiesPolicy';

import useRouter from 'use-react-router';

import { legalTabs } from 'components/menu/panels/legal';

const getActivePanelLabel = location => {
  if (location.pathname.startsWith('/legal')) {
    return 'legal';
  }

  if (location.pathname.startsWith('/tournaments')) {
    return 'tournament';
  }

  return '';
};

const getActiveIndex = (location, name) => {
  const tabs = {
    legal: legalTabs
  };

  if (location.pathname.startsWith(`/${name}`)) {
    const parts = location.pathname.split('/');

    if (parts && parts[2]) {
      const activeTabIndex = tabs[name].findIndex(t => t.path === parts[2]);

      if (activeTabIndex !== -1) {
        return activeTabIndex;
      }
    }
  }
};

const Menu = () => {
  const { location } = useRouter();

  const [menuIsOpen, setMenuIsOpen] = useState(
    location.pathname.startsWith('/legal') || location.pathname.startsWith('/tournaments')
      ? true
      : false
  );
  const [mobileView, setMovileView] = useState(
    location.search.startsWith('?mobile-view') ? true : false
  );
  const [activePanel, setActivePanel] = useState(getActivePanelLabel(location)); // eg join_us, career, legal etc
  const [isFlipped, setIsFlipped] = useState(true); // panels are shown with a flip effect

  const [activeIndex, setActiveIndex] = useState(() => {
    let index = 0;

    index = getActiveIndex(location, 'legal') || getActiveIndex(location, 'tournaments');

    return index;
  });

  useEffect(() => {
    setTimeout(() => {
      if (!activePanel) {
        setIsFlipped(false);
      } else {
        setIsFlipped(true);
      }
    }, 200);

    if (!menuIsOpen) {
      setTimeout(() => {
        setActivePanel('');
        setIsFlipped(false);
      }, 700);
    }

    if ((!menuIsOpen || activePanel !== 'legal') && location.pathname.startsWith('/legal')) {
      window.history.replaceState(null, 'Tatoi Club', '/'); // replace url without reload components
    }

    if (menuIsOpen) document.body.classList.add('menu-open');
    else document.body.classList.remove('menu-open');
  }, [activePanel, menuIsOpen]);

  return (
    <div>
      {mobileView ? null : <Sidebar menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />}
      <div
        className={`menu ${menuIsOpen ? 'open' : ''} ${isFlipped ? 'is-flipped' : ''} ${
          mobileView ? 'menu--mobile' : ''
        }`}
      >
        <div className="react-card-flip">
          <div className="h-100 w-100 d-flex menu-slide-container">
            <div key="front" className="d-flex menu-container slide">
              <MenuLinks
                setMenuIsOpen={setMenuIsOpen}
                activePanel={activePanel}
                setActivePanel={setActivePanel}
                setIsFlipped={setIsFlipped}
              />
            </div>
            <div key="back" className="slide">
              <MenuPanel
                activePanel={activePanel}
                setActivePanel={setActivePanel}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setMenuIsOpen={setMenuIsOpen}
                mobileView={mobileView}
              />
            </div>
          </div>
        </div>
        {mobileView ? null : (
          <MenuActions
            activePanel={activePanel}
            setActivePanel={setActivePanel}
            setIsFlipped={setIsFlipped}
          />
        )}
      </div>
      <div className={`menu-shadow ${menuIsOpen ? 'open' : ''}`} />
      {mobileView ? (
        ''
      ) : (
        <CookiesPolicy
          setActiveIndex={setActiveIndex}
          setMenuIsOpen={setMenuIsOpen}
          setActivePanel={setActivePanel}
        />
      )}
    </div>
  );
};

export default Menu;
