import React, { useState, useEffect, useRef } from 'react';
import { useInsertionToViewportCallback } from 'utils/hooks';

/*         _______
 *        |       |
 * title  | Image |
 * text   |       |
 *        |_______|
 *
 */

const PrimarySection = ({ title, text, image, leftText }) => {
  const sectionRef = useRef(null);
  const innerSection = useRef(null);
  const [showText, setShowText] = useState(false);

  const stickySection = () => {
    const el = document.querySelector('.left-text');
    if (!el) return;

    const section = document.querySelector('.section-left');
    const sreact = section.getBoundingClientRect();
    const app = document.querySelector('.app');

    if (sreact.top < 200) {
      el.style.top = el.style.transform.replace('translateY(', '').replace(') rotate(-90deg)', '');
      el.style.transform = `rotate(-90deg)`;
    } else {
      el.style.top = 0;
      el.style.transform = `translateY(${sreact.top + el.offsetWidth / 1.3}px) rotate(-90deg)`;
    }
  };

  useEffect(() => {
    const app = document.querySelector('.app');
    if (app) {
      stickySection();
      app.addEventListener('scroll', function(e) {
        stickySection();
      });
    }
  }, []);

  const sectionCallback = [
    {
      insertion: 0,
      includeHeight: false,
      callback: val => {
        if (val.visibleHeight > 40) {
          sectionRef.current.classList.add('animate');
        }
      }
    }
  ];

  useInsertionToViewportCallback(innerSection, sectionCallback);

  return (
    <div
      ref={sectionRef}
      className={`primary-section position-relative animate-sections ${
        !leftText ? 'overflow-hidden' : ''
      }`}
    >
      {leftText ? <div className={`left-text ${showText ? 'sticky' : ''}`}>{leftText}</div> : null}

      <div className="section max-width-1400" ref={innerSection}>
        <div className="section-left">
          <div className="section-title">
            <div className="title-container">
              <h3>{title}</h3>
            </div>
          </div>

          <div className="section-text">
            <p className="mb-0">{text}</p>
          </div>
        </div>
        <div className="section-right">
          <img src={image} />
        </div>
      </div>
    </div>
  );
};

export default PrimarySection;
