import React from 'react';
import ErrorMessage from './ErrorMessage';
import upload from 'assets/svg/menu/upload.svg';
import SvgRender from 'components/general/SvgRender';

const File = ({ placeholder, value, name = 'upload', error, status, onChange, accept }) => {
  return (
    <div className={`input-wrapper input-file-wrapper ${error ? 'error' : ''}`}>
      <label className={`${value.name ? 'with-file' : ''}`}>
        <div className={`placeholder ${value.name ? 'with-file font-weight-normal' : ''}`}>
          {value.name ? value.name : placeholder}
        </div>
        <input
          type="file"
          name={name}
          value={value && value.filename}
          accept={accept}
          onChange={onChange}
        />
        <div className="upload-icon">
          <SvgRender src={upload} />
        </div>
      </label>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {status && !value.name && <ErrorMessage>{status[1]}</ErrorMessage>}
    </div>
  );
};

export default File;
