import React, { Fragment } from 'react';
import PrimarySection from 'components/page/PrimarySection';
import SecondarySection from 'components/page/SecondarySection';
import SectionsHeader from 'components/page/SectionsHeader';
import Helmet from 'components/general/Helmet';

import onCourtTraining from 'assets/images/be-active/tennis/on-court-training.jpg';

import courtsPrimary from 'assets/images/be-active/tennis/courts-primary.jpg';
import courtsSecondary from 'assets/images/be-active/tennis/courts-secondary.jpg';

import internationalCompetition1_1 from 'assets/images/be-active/tennis/international-competition-1.1.jpg';
import internationalCompetition1_2 from 'assets/images/be-active/tennis/international-competition-1.2.jpg';
import internationalCompetition2_1 from 'assets/images/be-active/tennis/international-competition-2.1.jpg';
import internationalCompetition2_2 from 'assets/images/be-active/tennis/international-competition-2.2.jpg';

import generationPrimary from 'assets/images/be-active/tennis/generation-primary.jpg';
import generationSecondary from 'assets/images/be-active/tennis/generation-secondary.jpg';
import ScrollButton from 'components/general/ScrollButton';
import PageBottomNav from 'components/general/PageBottomNav';

const primaryImages = [internationalCompetition1_2, internationalCompetition1_1];
const secondaryImages = [internationalCompetition2_2, internationalCompetition2_1];

const Tennis = () => {
  return (
    <Fragment>
      <Helmet
        title="Tennis - Enjoying The Game at Tatoi Club"
        content="Tennis at Tatoi Club: 11 outdoor courts, 4 indoor courts and 3 mini tennis courts. highest-level professional training. Click here to find out more!"
      />
      <PrimarySection
        title="ENJOYING THE GAME"
        text="11 outdoor courts, 4 indoor courts and 3 mini tennis courts, fully compliant with construction standards required for the highest-level professional training, are at the disposal of our Members and their Guests. "
        image={onCourtTraining}
        leftText={'Be Active'}
      />
      <SectionsHeader header="Unique Experience" animate />
      <SecondarySection
        title="PERFORMANCE EXCELLENCE"
        text="Special emphasis is given to on court training under the supervision of professional coaches and total training support, including regular ergometric exams, physiotherapy and medical support."
        primaryImage={courtsPrimary}
        secondaryImage={courtsSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
      />
      <SecondarySection
        title="INTERNATIONAL COMPETITION"
        text="Hosting major international events, such as the Fed Cup EUROPE-AFRICA Group II and Davis Cup EUROPE GROUP III, which attract both national and international attention, made the Club the focal point for tennis enthusiasts the world over.  The Club also functions as an international training hub for well-known world class athletes, by offering an integrated fitness programme of the highest standards and a total wellbeing experience."
        primaryImages={primaryImages}
        secondaryImages={secondaryImages}
        primaryImageAtRight
        alignRight
        hasSlides={true}
        slideImages
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
      />
      <SecondarySection
        title="CREATE THE FUTURE GENERATION"
        text="The Tennis Academy’ s aim is to offer a holistic approach to the growth and development of young athletes. Discipline and respect, new ways of instruction, healthy competition and teamwork are the pillars of the Academy’s structure."
        primaryImage={generationPrimary}
        secondaryImage={generationSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
      />
      <ScrollButton />

      <PageBottomNav label="FITNESS" path="/be-active/fitness" next />
    </Fragment>
  );
};

export default Tennis;
