import React, { Fragment } from 'react';
import PrimarySection from 'components/page/PrimarySection';
import SecondarySection from 'components/page/SecondarySection';
import SectionsHeader from 'components/page/SectionsHeader';

import treatments from 'assets/images/invigorous/treatments.jpg';

import nutritionPrimary from 'assets/images/invigorous/nutrition-primary.jpg';
import nutritionSecondary from 'assets/images/invigorous/nutrition-secondary.jpg';

import cryotherapyPrimary from 'assets/images/invigorous/cryotherapy-primary.jpg';
import cryotherapySecondary from 'assets/images/invigorous/cryotherapy-secondary.jpg';

import physiotherapyPrimary from 'assets/images/invigorous/physiotherapy-primary.jpg';
import physiotherapySecondary from 'assets/images/invigorous/physiotherapy-secondary.jpg';

import spaPrimary from 'assets/images/invigorous/spa-primary.jpg';
import spaSecondary from 'assets/images/invigorous/spa-secondary.jpg';

import ScrollButton from 'components/general/ScrollButton';

const InvigorousPage = () => {
  return (
    <Fragment>
      <PrimarySection
        title="BALANCE, ENERGY & REJUVENATION"
        text="Personalized programmes of stimulation, revitalization, prevention and rehabilitation are designed to offer the best results."
        image={treatments}
        leftText={'Be Vigorous'}
      />
      <SectionsHeader header="Overall Wellbeing" animate />
      <SecondarySection
        title="CRYOTHERAPY"
        text="Whole Body Cryotherapy offers a multitude of benefits, such as good physical health, pain relief, weight management and beauty enhancement, achieved by the exposure of the body to extreme cold. "
        primaryImage={cryotherapyPrimary}
        secondaryImage={cryotherapySecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
        className="cryotherapy"
      />
      <SecondarySection
        className="physiotherapy"
        title="PHYSIOTHERAPY"
        text="Physiotherapy training complements the integrated fitness approach with a high level of services for active rehabilitation, including indiba, ergon, compex and clinical pilates. A team of qualified scientists are able to conduct a fitness assessment and advise on the improvement of physical condition."
        primaryImage={physiotherapyPrimary}
        secondaryImage={physiotherapySecondary}
        primaryImageAtRight
        animateSection={true}
        alignRight
        offsetLeft={76}
        offsetRight={76}
      />
      <SecondarySection
        title="SPA"
        text="Offering a wide selection of facial and body wellness treatments and equipped with separate men’s and women’s sauna and steam rooms, the Spa is the ideal place for a tailored relaxation and rejuvenation experience."
        primaryImage={spaPrimary}
        secondaryImage={spaSecondary}
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
        className="spa"
      />

      <SecondarySection
        className="nutrition"
        title="NUTRITION"
        text="Individualized nutritional programmes are designed according to the particular metabolic function of each body, its temperament and its specific needs in order to improve and maintain well-being and health."
        primaryImage={nutritionPrimary}
        secondaryImage={nutritionSecondary}
        primaryImageAtRight
        alignRight
        animateSection={true}
        offsetLeft={76}
        offsetRight={76}
      />
      <ScrollButton />
    </Fragment>
  );
};

export default InvigorousPage;
