import React from 'react';
import SinglePage from 'components/page/SinglePage';
import BeOurGuest from './BeOurGuest';
import cover from 'assets/images/be-our-guest/cover.jpg';
import coverMobile from 'assets/images/be-our-guest/cover-mobile.jpg';

const BeOurQuest = props => {
  const page = {
    name: 'Be our Guest',
    abbrev: 'IN',
    cover: cover,
    content: BeOurGuest,
    coverMobile,
    coverStyle: { objectPosition: 'right' },
    tabs: [
      {
        button: 'THE GUESTHOUSE',
        key: 'the-guesthouse'
      },
      {
        button: 'THE BUSINESS HUB',
        key: 'the-business-hub'
      }
    ]
  };

  return <SinglePage page={page} title="Be our Guest at Tatoi Club" />;
};

export default BeOurQuest;
