import React from 'react';

const Cookies = () => {
  return (
    <div>
      <p>
        Cookies are small data files sent to your browser when you visit a site. We use both our own
        cookies, as well as third-party cookies, to do a few different things:
      </p>
      <ul>
        <li>Log you in to our services</li>
        <li>Remember preferences and settings</li>
        <li>Keep your account secure</li>
        <li>
          Better understand how people are using our services, and improve and promote those
          services based on that information
        </li>
      </ul>
      <p>Categories of cookies that we use</p>
      <ul>
        <li>
          Necessary Cookies, such as Session duration cookies. These cookies are necessary to ensure
          the operation and functionalities of our web page.
        </li>
        <li>
          Performance Cookies (Google analytics: where you click on, the pages you visit etc.) allow
          us to optimize our services by analyzing website traffic. This information is aggregated
          and, therefore, anonymized. None of this information is used to identify the user/guest of
          the website.
        </li>
        <li>
          Preference Cookies, such as those set on your device by third-party service providers,
          allowing them to understand your advertising preferences, so they can show you ads that
          are more relevant to your interests. Preference cookies help you receive personalized
          services.{' '}
        </li>
      </ul>
      <p>
        Pixels: A pixel tag ("pixel") is a small piece of code that can be embedded on websites and
        emails. We use pixels and cookies to learn how you interact with our webpages and emails.
        This information helps us, and the advertising services providers we use, better promote our
        services. For example, if you visit one of our TATOI pages, you may later see an ad for
        TATOI when you visit a wellness news site.
      </p>
      <p>
        Data protection legislation allows us to place Necessary cookies in your computer browser
        without any action (e.g. consent) on your part. By using our webpage, you agree to the use
        of necessary cookies.
      </p>
      <p>
        For other types of cookies (such as performance and preference cookies), you have the right
        to accept or reject them by using the respective buttons of the dedicated banner when you
        enter our web page. We will remember your choice for (e.g.: 6 months/1 year), and will only
        ask you to confirm it when this period is over, or when you connect from a different browser
        or device.
      </p>
      <p>
        In addition, TATOI adheres to the Digital Advertising Alliance’s Self-Regulatory Principles
        for Online Behavioral Advertising ("Principles"). You can opt-out of collection of
        information by third parties that adhere to the Principles and that we use to help us
        promote our services by visiting{' '}
        <a href="http://www.aboutads.info/choices" target="_blank">
          www.aboutads.info/choices
        </a>
        .
      </p>
    </div>
  );
};

export default Cookies;
