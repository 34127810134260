import React from 'react';
import { Helmet } from 'react-helmet';

const CustomHelmet = ({ title, content }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {content ? <meta name="description" content={content} /> : null}
    </Helmet>
  );
};

export default CustomHelmet;
