import React from 'react';

const About = ({ setActivePanel }) => {
  return (
    <div className="menu-panel about overflow-hidden h-100">
      <div className="menu-panel-title">ABOUT US</div>
      <div className="h-100 pb-5 pb-xl-10 about-us-wrapper">
        <div className="about-us-scrollable">
          <div className="font-weight-light mb-2 mt-xl-8 text-primary">
            <p>
              TATOΪ Club is a private members club situated amidst a beautiful natural landscape not
              far from the city of Athens. At the core of its philosophy is a holistic approach to
              wellness. The Club focuses on the overall wellbeing of its Members and Guests by
              offering a wide range of tailored experiences that both complement and complete each
              other.
            </p>
          </div>
          <div className="font-weight-light second-text mb-4 text-primary">
            <p>
              TATOΪ Clubs offers a wide range of Memberships for individuals and families. It also
              offers an opportunity for Corporate Membership. To enroll in the TATOΪ Club’s
              Register, submission of an application is required, along with the written
              recommendations from two current Members.
            </p>
          </div>

          <div className="contact-info d-flex mt-xl-9 justify-content-center">
            <div className="about-us">
              <div className="menu-panel-title">CONTACT</div>

              <p className="line-height-16 address">22 Erythreas Rd, 136 77 Varibobi</p>
              <p className="line-height-16">T: +30 214 416 6000</p>
              <p className="line-height-16">F: +30 214 416 6001</p>
              <p className="line-height-16">E: iam@tatoiclub.com</p>
            </div>
          </div>
          <div className="font-weight-light second-text mt-3 mt-sm-10 text-primary">
            <p>
              Chamber of Commerce registration number:{' '}
              <span className="font-weight-medium">120846101001</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
