import React, { useState, useEffect } from 'react';
import Menu from 'components/menu';
import { Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga';

import NotFound from 'views/errors/NotFound';

import routes from './_routes';

const App = ({ history, location }) => {
  const [initializeGA, setInitializeGA] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GA_KEY);
      setInitializeGA(true);
    }
  }, []);

  useEffect(() => {
    if (initializeGA) {
      ReactGA.ga('send', 'pageview', location.pathname + history.location.search);
    }
  }, [initializeGA, location.pathname]);

  useEffect(() => {
    if (location.pathname !== '/') {
      document.body.style.height = '';
      document.body.classList.remove('overflow-hidden');
    }
  }, [location.pathname]);

  return (
    <TransitionGroup className="app-wrapper">
      <CSSTransition appear={true} key={location.key} timeout={500} classNames={'navigation'}>
        <div className="app">
          <Switch>
            {routes.map(route => (
              <Route key={route.path} path={route.path} exact component={route.component} />
            ))}
            <Route component={NotFound} />
          </Switch>
          <Menu />
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default withRouter(App);
