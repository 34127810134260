import React, { useRef, useEffect, useState, useCallback } from 'react';
import Slider from 'react-slick';
import Slide from './Slide';
import Helmet from 'components/general/Helmet';
// import Div100vh from 'react-div-100vh';
// import DavisCupModal from 'components/general/DavisCupModal';

import getIntoAction from 'assets/images/landing-slider/web/get-into-action.jpg';
import feelInvigorated from 'assets/images/landing-slider/web/feel-invigorated.jpg';
import eatWisely from 'assets/images/landing-slider/web/eat-wisely.jpg';
import beBest from 'assets/images/landing-slider/web/be-best.jpg';
import sleepWell from 'assets/images/landing-slider/web/sleep-well.jpg';

import getIntoActionTab from 'assets/images/landing-slider/tablet/get-into-action.jpg';
import feelInvigoratedTab from 'assets/images/landing-slider/tablet/feel-invigorated.jpg';
import eatWiselyTab from 'assets/images/landing-slider/tablet/eat-wisely.jpg';
import beBestTab from 'assets/images/landing-slider/tablet/be-best.jpg';
import sleepWellTab from 'assets/images/landing-slider/tablet/sleep-well.jpg';

import getIntoActionMobile from 'assets/images/landing-slider/mobile/get-into-action.jpg';
import feelInvigoratedMobile from 'assets/images/landing-slider/mobile/feel-invigorated.jpg';
import eatWiselyMobile from 'assets/images/landing-slider/mobile/eat-wisely.jpg';
import beBestMobile from 'assets/images/landing-slider/mobile/be-best.jpg';
import sleepWellMobile from 'assets/images/landing-slider/mobile/sleep-well.jpg';

import 'slick-carousel/slick/slick.css';
import SvgRender from 'components/general/SvgRender';
import login from 'assets/svg/common/login.svg';

const Landing = props => {
  const [documentHeight, setDocumentHeight] = useState('100vh');

  const slider = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    fade: true,
    easing: 'ease-in-out',
    onInit: () => {
      //make sure it play on page load
      setTimeout(() => {
        if (slider.current) slider.current.slickPlay();
      }, 1000);
    }
  };

  const setCustomVH = () => {
    // Set the value in the --vh custom property to the root of the document
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // const height = document.documentElement.clientHeight || window.innerHeight || '100vh';
    // setDocumentHeight(typeof height === 'string' ? height : `${height}px`);
  };

  const resizeListener = useCallback(() => {
    setTimeout(setCustomVH, 500);
  }, []);

  const touchendListener = useCallback(e => {
    //for some reasons slider slider not work on first click in tablet
    const parent = e.target.parentNode;
    if (!slider.current) return;

    if (
      parent &&
      typeof parent.className == 'string' &&
      parent.className.indexOf('slick-dots') > -1
    ) {
      const btn = e.target.querySelector('button');
      if (btn) {
        const goTo = parseInt(btn.innerHTML) - 1;
        setTimeout(() => {
          slider.current.slickGoTo(goTo);
        }, 200);
      }
    }
  }, []);

  const touchmoveListener = useCallback(e => {
    const { history } = props;
    if (history && history.location.pathname == '/') e.preventDefault();
  }, []);

  useEffect(() => {
    document.body.style.height = documentHeight;
    document.body.classList.add('overflow-hidden');
  }, [documentHeight]);

  useEffect(() => {
    // setCustomVH();

    // window.addEventListener('resize', resizeListener);
    document.addEventListener('touchend', touchendListener);
    document
      .querySelector('.landing-wrapper')
      .addEventListener('touchmove', touchmoveListener, { passive: false });

    return () => {
      // window.removeEventListener('resize', resizeListener);
      document.removeEventListener('touchend', touchendListener);
      document
        .querySelector('.landing-wrapper')
        .removeEventListener('touchmove', touchmoveListener);
    };
  }, []);

  const slides = [
    {
      location: 'Clay Court 1, Tennis Courts',
      time: '08:30 AM',
      event: 'Match Point',
      label: 'Get into action',
      image: getIntoAction,
      className: 'object-bottom',
      tabletImage: getIntoActionTab,
      mobileImage: getIntoActionMobile
    },
    {
      location: 'Pool Area, Splash! Cool ',
      time: '10:00 AM',
      event: 'Refreshing Morning Swim',
      label: 'Feel invigorated',
      image: feelInvigorated,
      tabletImage: feelInvigoratedTab,
      mobileImage: feelInvigoratedMobile
    },
    {
      location: 'Pool Area, Splash! Pool Bar - Restaurant',
      time: '14:30 PM',
      event: 'Energy Brunch',
      label: 'Eat wisely',
      image: eatWisely,
      tabletImage: eatWiselyTab,
      mobileImage: eatWiselyMobile
    },
    {
      location: 'Outdoor Area, Herb Garden',
      time: '17:00 PM',
      event: 'Fresh Air Run',
      label: 'Be your best self',
      image: beBest,
      tabletImage: beBestTab,
      mobileImage: beBestMobile
    },

    {
      location: 'Outdoor Area, Loft',
      time: '19:30 PM',
      event: 'Relaxing Break',
      label: 'Sleep well',
      image: sleepWell,
      tabletImage: sleepWellTab,
      mobileImage: sleepWellMobile
    }
  ];

  return (
    <div className="landing-wrapper" style={{ height: documentHeight }}>
      <Helmet
        title="Tatoi Club"
        content="Tatoi Club: Based on the values of well-being, inspiration and creativity. Tatoi Club Members can enjoy socialising, relaxing and healthy living. Click here to find out more!"
      />

      <div style={{ height: documentHeight }}>
        <Slider ref={slider} {...settings} className="main-slider">
          {slides.map((slide, index) => (
            <Slide slide={slide} key={index} />
          ))}
        </Slider>
      </div>

      <div className="position-fixed btn-login btn">
        <a
          href={process.env.REACT_APP_MEMBERS_SITE_URL}
          target="_self"
          className="d-inline-block pointer"
        >
          <SvgRender src={login} />
        </a>
      </div>

      {/* <DavisCupModal /> */}
    </div>
  );
};

export default Landing;
