import React, { useRef, Fragment, useLayoutEffect } from 'react';
import { useInsertionToViewportCallback } from 'utils/hooks';
import Slider from 'react-slick';
import vanillaJsuParallax from 'utils/Rellax';
import 'slick-carousel/slick/slick.css';

/*  _______
 * |       |  Title
 * | Prim  |  Text
 * | Image |   _______
 * |_______|  |       |
 *            | Sec   |
 *            | Image |
 *            |_______|
 *
 *  And alernate
 *
 */

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 6000,
  initialSlide: 1,
  pauseOnHover: false,
  swipe: true,
  fade: true,
  easing: 'ease-in-out'
};

const SecondarySection = ({
  title,
  text,
  primaryImage,
  secondaryImage,
  primaryImageAtRight,
  offsetRight,
  offsetLeft,
  alignRight,
  animateSection,
  className,
  hasSlides,
  secondaryImages,
  primaryImages
}) => {
  const leftSection = useRef(null);
  const rightSection = useRef(null);
  const section = useRef(null);
  const bottomLine = useRef(null);

  const leftSlider = useRef(null);
  const rightSlider = useRef(null);

  const insertionCallbacks = [
    {
      insertion: 0,
      callback: val => animate(val)
    }
  ];

  var timer = setInterval(() => {
    if (rightSection.current) {
      clearInterval(timer);
      if (window.innerWidth < 548) {
        return;
      }
      new vanillaJsuParallax({
        items: [rightSection.current, bottomLine.current, leftSection.current],
        minViewportHeight: 20,
        callback: (el, height) => {}
      });
    }
  }, 1000);

  const animate = val => {
    const { height, visibleHeight, el, top } = val;

    if (visibleHeight > 40 && !section.current.classList.contains('animate')) {
      section.current.classList.add('animate');
      //clear cube-path and overflow for twin image animation
      setTimeout(() => {
        if (!section.current) return;
        section.current.classList.add('animated');
      }, 900);
    }
  };

  useInsertionToViewportCallback(section, insertionCallbacks);

  const renderTextAndSecondaryImage = (ref, dataset) => {
    const leftSettings = {
      ...settings,
      beforeChange: (current, next) => rightSlider.current.slickGoTo(next)
    };
    return (
      <Fragment>
        <div className={`tablet-section`}>
          <div className="section-title">
            <div className="title-container">
              <h3>{title}</h3>
            </div>
          </div>
          <div className="section-text overflow-hidden">
            <p>{text}</p>
          </div>
        </div>
        {hasSlides ? (
          <Slider {...leftSettings} className="secondary-slider" ref={leftSlider}>
            <img
              data-y={dataset}
              data-jsuplxdir={'top'}
              ref={dataset ? ref : null}
              src={secondaryImages[0]}
            />
            <img
              data-y={dataset}
              data-jsuplxdir={'top'}
              ref={dataset ? ref : null}
              src={secondaryImages[1]}
            />
          </Slider>
        ) : (
          <img
            data-y={dataset}
            data-jsuplxdir="top"
            ref={dataset ? ref : null}
            src={secondaryImage}
          />
        )}
      </Fragment>
    );
  };

  const renderPrimaryImage = (ref, dataset) => {
    const rightSettings = {
      ...settings,
      beforeChange: (current, next) => leftSlider.current.slickGoTo(next)
    };
    if (hasSlides) {
      return (
        <Slider {...rightSettings} className="secondary-slider" ref={rightSlider}>
          <img
            data-y={dataset}
            data-jsuplxdir={'bottom'}
            ref={dataset ? ref : null}
            className={'primary-img'}
            src={primaryImages[0]}
          />
          <img
            data-y={dataset}
            data-jsuplxdir={'bottom'}
            ref={dataset ? ref : null}
            className={'primary-img'}
            src={primaryImages[1]}
          />
        </Slider>
      );
    } else {
      return (
        <img
          data-jsuplxdir="bottom"
          data-y={dataset}
          className="primary-img"
          ref={dataset ? ref : null}
          src={primaryImage}
        />
      );
    }
  };

  return (
    <div
      className={`secondary-section ${className || ''} ${
        primaryImageAtRight ? 'primary-right' : ''
      }`}
    >
      <div className={`section mobile-section ${primaryImageAtRight ? 'section-left' : ''}`}>
        <div className="">
          <div className="section-title">
            <div className="title-container">
              <h3>{title}</h3>
            </div>
          </div>
          <div className={`section-text overflow-hidden ${alignRight ? 'align-right' : null}`}>
            <p>{text}</p>
          </div>
        </div>
      </div>
      <div
        ref={section}
        className={`section  ${animateSection ? 'animate-sections' : ''} max-width-1400`}
      >
        {primaryImageAtRight ? (
          <Fragment>
            <div className={`section-left small-image`}>
              {renderTextAndSecondaryImage(leftSection, offsetLeft ? offsetLeft : null)}
            </div>
            <div className={`section-right`}>
              {renderPrimaryImage(rightSection, offsetRight ? offsetRight : null)}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className={`section-left`}>
              {renderPrimaryImage(leftSection, offsetLeft ? offsetLeft : null)}
            </div>
            <div className={`section-right small-image`}>
              {renderTextAndSecondaryImage(rightSection, offsetRight ? offsetRight : null)}
            </div>
          </Fragment>
        )}
      </div>
      <div ref={bottomLine} data-y={40} className="bottom-line" />
    </div>
  );
};

export default SecondarySection;
