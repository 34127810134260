import React from 'react';
import Tabs from 'components/general/Tabs';
import Privacy from './Privacy';
import Terms from './Terms';
import Cookies from './Cookies';
import CameraPolicy from './CameraPolicy';

import useRouter from 'use-react-router';

export const legalTabs = [
  {
    button: 'PRIVACY POLICY',
    content: Privacy,
    key: 'legal_policy',
    path: 'privacy-policy'
  },

  {
    button: 'TERMS & CONDITIONS',
    content: Terms,
    key: 'legal_terms',
    path: 'terms-and-conditions'
  },

  {
    button: 'COOKIES POLICY',
    content: Cookies,
    key: 'legal_cookies',
    path: 'cookies-policy'
  },

  {
    button: 'CAMERA POLICY',
    content: CameraPolicy,
    key: 'legal_camera_policy',
    path: 'camera-policy'
  }
];

const Legal = ({ activeIndex, mobileView, setActiveIndex, activePanel, setActivePanel }) => {
  const { location } = useRouter();

  const handleTabClick = tabIndex => {
    if (location.pathname.startsWith('/legal')) {
      const activeTab = legalTabs[tabIndex];

      window.history.replaceState(null, 'Tatoi Club', `/legal/${activeTab.path}`); // replace url without reload components
    }
  };

  return (
    <div
      className={`${
        mobileView ? '' : 'pb-4'
      } pb-md-12 menu-panel overflow-hidden d-flex flex-column legal`}
    >
      <div className="overflow-hidden d-flex flex-column">
        <div className="menu-panel-title">LEGAL</div>
        <div className="flex-fill overflow-hidden d-flex flex-column about-us-wrapper">
          <Tabs
            initialIndex={activeIndex}
            tabs={legalTabs}
            dropdown={true}
            mobileView={mobileView}
            handleTabClick={handleTabClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Legal;
