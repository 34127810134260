import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import Helmet from 'components/general/Helmet';

import PageNavigation from './PageNavigation';
import Cover from './Cover';

const Page = ({ page: { name, abbrev, tabs }, history, location: { pathname }, match, title }) => {
  const [activeTabIndex, setActiveTabIndex] = useState({ index: 0, force: false, init: false });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (match.params.active && !activeTabIndex.force) {
      const activePage = match.params.active;
      let activeIndex = tabs.findIndex(f => f.key == activePage);

      activeIndex = activeIndex >= 0 ? activeIndex : 0;
      if (activeTabIndex.index != activeIndex) {
        setActiveTabIndex({ index: activeIndex, init: true });
      } else {
        setActiveTabIndex({ ...activeTabIndex, init: true });
      }
    } else {
      setActiveTabIndex({ ...activeTabIndex, init: true });
    }
  }, []);

  useEffect(() => {
    if (activeTabIndex.init) {
      if (isLoaded) {
        setIsLoaded(false);
      } else {
        setTimeout(() => {
          setIsLoaded(true);
        }, 800);
      }
    }
  }, [activeTabIndex]);

  const renderActiveCover = () => {
    const activeTab = tabs[activeTabIndex.index];

    return (
      <Cover
        key={activeTab.button}
        coverStyle={activeTab.coverStyle ? activeTab.coverStyle : {}}
        cover={activeTab.cover}
        coverType={activeTab.coverType}
      />
    );
  };

  const renderActiveContent = () => {
    const ActiveTabContent = tabs[activeTabIndex.index].content;
    return <ActiveTabContent />;
  };

  const path = pathname.replace(`/${match.params.active}`, '');

  return (
    <div
      className={`page screen-divided-effect ${isLoaded && activeTabIndex.init ? 'is-loaded' : ''}`}
      key={activeTabIndex.index}
    >
      <Helmet title={title} />
      {renderActiveCover()}
      <PageNavigation
        name={name}
        abbrev={abbrev}
        tabs={tabs}
        activeTabIndex={activeTabIndex.index}
        setActiveTabIndex={active => {
          setActiveTabIndex({ ...active, init: false });
          history.push(`${path}/${active.key}`);
        }}
      />
      {renderActiveContent()}
    </div>
  );
};

export default withRouter(Page);
