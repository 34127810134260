import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found-wrapper p-10">
      <div className="not-found--info mt-xl-5">
        <div className="not-found--info-text gotham-200">404 - Page not found</div>
      </div>

      <div className="back-home">
        <Link to="/">Go to Homepage</Link>
      </div>
    </div>
  );
};

export default NotFound;
