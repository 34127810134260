import React from 'react';

import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div>
      <p>
        This privacy and data protection notice tells you in simple words what to expect us to do
        with your personal information when you make contact with us by visiting our webpage, by
        joining us as a guest or by becoming our Member.
      </p>
      <p>
        <strong>We’ll tell you:</strong>
      </p>
      <ul>
        <li>
          <span>Who we are and how to contact us</span>
        </li>
        <li>
          <span>What types of data we are processing</span>
        </li>
        <li>
          <span>Why we are processing your data and how long we keep it</span>
        </li>
        <li>
          <span>Whether we share your data with anyone else</span>
        </li>
        <li>
          <span>Whether we transfer data outside of the European Economic Area (EEA)</span>
        </li>
        <li>
          <span>How we keep your data safe</span>
        </li>
        <li>
          <span>Your rights</span>
        </li>
      </ul>
      <p>
        <strong>Who we are and how to contact us</strong>
      </p>
      <p>
        We are «<strong>TATOI CLUB T.C. LIMITED</strong>», a private company offering you a
        well-organized space with sports, wellness, and hospitality facilities, as well as catering
        premises. We operate as a Country Club, offering holistic and personalized entertaining,
        athletic and social recreational services. We fully respect the protection of your privacy,
        and we comply with all relevant EU and national legislation, including the General Data
        Protection Regulation (EE) 2016/679 (hereinafter G.D.P.R.) and the Greek legislation on the
        protection of personal data. Therefore, we take every possible measure to safeguard your
        rights and freedoms in relation to personal data protection and safety. We are the data
        controller with regard to the processing of your personal data.
      </p>
      <p>There are many ways you can contact us, including by phone, email, and post.</p>
      <p>Our postal address: Erithreas 22, Varibobi, 13677, Greece</p>
      <p>Helpline number: +30 214 4166000</p>
      <p>
        Email address: <a href="mailto:iam@tatoiclub.com">iam@tatoiclub.com</a>
      </p>
      <p>
        Our Data Protection Officer is Mr Pericles Paschakis, Lawyer accredited to the Supreme Court
        of Greece, specialized in data protection. You can contact him via e-mail at{' '}
        <a href="mailto:pp@paschakislaw.com">pp@paschakislaw.com</a> or by phone (+30) 210-3647810,
        09.00-16.00 Monday - Friday.
      </p>
      <p>
        <strong>What types of data we are processing</strong>
      </p>
      <p>
        We collect data about you when you visit our webpage, join us as a guest, become our Member,
        and use our services.
      </p>
      <p>
        We process the following data that you provide to us when you become our Member: personal
        details such as name, surname, age, postal address, e-mail address, VAT number, phone
        numbers, contact details and information of your next of kin, photograph, gender,
        profession, ID/driving licence/passport/student card details.
      </p>
      <p>
        We also process data during your use of our Services, such as location data and other
        communication data (including IP address and browser type collected by your availing of the
        Services), membership number and information, your usage of our goods and services,
        payments, payment method and other account transactions, information about how you use and
        interact with Tatoi Club and the Digital Properties, such as when you use our Digital
        Properties to check-in to the Tatoi Club, log a workout, book a class or add it to your
        schedule, purchase training sessions, or schedule personal training or spa appointments. We
        also process data concerning your health, to ensure that you safely use our Services.
      </p>
      <p>
        We collect data when you contact us via phone, email or social media, including email
        conversations – either to place an enquiry or customer service emails, social media profile
        details (name, profile photo and other information you make available) when you connect with
        or contact us through a social media account. We also record phone calls through our call
        centre recording system.
      </p>
      <p>
        When you visit our web page, we use cookies. We collect data such as: device information
        including unique device identifiers, pages visited and content viewed, links and buttons
        clicked, URLs visited, IT equipment use including telephones and internet access. Please
        read more details about our <Link to="/legal/cookies-policy">cookies policy here</Link>.
      </p>
      <p>
        We may collect information about you, but not coming from you, in case you elect to connect
        to any third-party fitness devices or third-party applications (collectively “Third Party
        Apps”) in order to utilize certain features and functionalities, such us the payments
        gateways like Braintree - a Paypal Company. We also collect data for you but not coming from
        you, when a Member of ours makes a reference for you so as for you to make a membership
        request.
      </p>
      <p>
        We also utilize a face recognition access system at the entrance of our premises. This
        system uses a method of pseudonymisation of biometric data, replacing them with encrypted
        non reversible copies, which are extracted through one way encryption techniques and hash
        functions. The extraction of the authentic biometric data out of the system is therefore not
        possible. In simple words the face is replaced with a code. We use this data for the
        identification of the individual only, combined with other relevant data already known to
        us. Use of this system is not compulsory. This optional Access System has been introduced as
        a more practical entrance/exit method, in line with the security protocols of the Club,
        which are in place in consideration of the fact that our Member base is made up of important
        figures of the political, economic, cultural and social fields of our country.
      </p>
      <p>
        We try to minimize the data we collect, and to only process data that is absolutely
        necessary for the required purposes. We keep this data in individual physical files as well
        as on our computer systems.
      </p>
      <p>
        <strong>Why we are processing your data and how long we keep it</strong>
      </p>
      <p>
        We process your data in order to enter into a contract with you at your request (membership
        application) and for the performance and optimisation of our contract (membership
        regulation). This means that we process your data to ensure that you are provided with the
        best and all the benefits of your membership as described in the membership regulation, to
        deliver personalized services, to keep you updated about our services and our happenings, to
        determine reasonable adjustments, to ensure you are fit and able to exercise and use our
        facilities and to assure as much as possible your safety and the safety of your guests and
        your children.
      </p>
      <p>
        Your failure to provide us with the data that is necessary for the performance of our
        contract (membership regulation) means that we are unable to fulfil our high standards and
        requirements for entering into a contract of membership with you or to renew your
        membership. This could include being unable to offer you club access, or administer
        contractual benefits.
      </p>
      <p>
        In other cases, such as when we collect special category data including data concerning your
        health, we do so only after you have given us your explicit consent. In such cases, you may
        withdraw your consent, but doing so will not affect the lawfulness of processing before
        withdrawal. You may withdraw your consent at any time by communicating by e-mail with the
        Club at <a href="mailto:iam@tatoiclub.com">iam@tatoiclub.com</a> or with our Data Protection
        Officer (DPO) at <a href="mailto:pp@paschakislaw.com">pp@paschakislaw.com</a>.
      </p>
      <p>
        As part of our efforts to provide members with a more personalized experience and in order
        to optimize the performance of our contract (membership regulation) we may combine
        information regarding a Member that was collected through the Digital Properties with other
        information that we have collected from a Member in the course of his or her membership to
        us, including, without limitation, information collected offline when the Member checks in
        and uses our facilities, information the Member volunteers when completing assessments and
        questionnaires regarding physical health, nutrition and fitness goals, and information about
        the Member such as training logs, provided by personal trainers. Or goal is to analyse and
        improve the offered services (including on or through the Digital Properties), better
        understanding our business and Members, evaluating products and services that may be of
        interest to you, and carrying out internal purposes and research and otherwise operating our
        business for the benefit of the Members. This may include aggregating information regarding
        our Members across our entire user base or part of our user base.
      </p>
      <p>
        We only keep your data for as long as we need it for the required purposes, which will be at
        least for the duration of your membership to our club, though in some cases we will keep
        your data for a period after your membership has ended. Some data retention periods are set
        by law. No decision about you which has a significant impact on you will be made solely on
        the basis of automated decision making (i.e. using an electronic system without human
        involvement).
      </p>
      <p>
        <strong>Who we share your data with</strong>
      </p>
      <p>
        Employees within our club who have responsibility for membership administration and for our
        services will only have access to your data which is relevant to their function. Our
        relationship to them is governed by binding rules concerning their liability for the use of
        such data. We may share some of your personal data (e.g. location in the club, usage of
        services, participation in tournaments) with other members, exclusively for the
        effectiveness of your membership.
      </p>
      <p>
        We may disclose your information to third-party service providers for the purposes of
        providing services to us or directly to you on our behalf. When we use third party service
        providers, we only disclose to them personal information that is necessary for them to
        provide their service, and we have a contract in place that requires them to keep your
        information secure and not to use it other than in accordance with our specific
        instructions. We have a data processing agreement in place with such third parties to ensure
        data is not compromised. Third parties are obliged to implement appropriate technical and
        organisational measures to ensure the security of your data.
      </p>
      <p>
        Where we provide links to websites of other organisations, this privacy and data protection
        notice does not cover how that organisation processes personal information. We encourage you
        to read the privacy and data protection notices on the other websites you visit.
      </p>
      <p>We work to high standards when it comes to processing your personal information.</p>
      <p>
        <strong>Whether we transfer data outside of the European Economic Area (EEA)</strong>
      </p>
      <p>
        As a rule, we do not transfer your data outside the European Economic Area. When we do so,
        we use platforms and apps registered in the USA, such as Mailchimp. Following the recent
        changes regarding data transfers to the USA (Court of Justice of the EU, Judgment Schrems
        II, C-311/18), and in anticipation of the new adequacy decision for the EU-US Data Privacy
        Framework, our company ensures that data processors based in USA meet an adequate level of
        protection pursuant to the GDPR and comply with the requirements set out by the EU CoJ as
        well as the new contractual clauses for data transfers between EU and non-EU countries,
        issued by the European Commission (Commission Implementing Decision (EU) 2021/914 of 4 June
        2021). We remain up to date with new developments in this area and comply with any new
        legislation and case law.
      </p>
      <p>
        <strong>How we keep your data safe</strong>
      </p>
      <p>
        We are aware of the requirement to ensure that your data is protected against accidental or
        unlawful processing and against accidental destruction, loss or damage. We have implemented
        processes to guard against such risks. We have adopted the technical and organisational
        measures necessary to ensure the security of the personal data we collect, use and maintain,
        and to prevent any alteration, loss, unauthorised processing or access, having regard to the
        state of the art, the nature of the data stored and the risks to which they are exposed by
        human action or physical or natural environment. Such measures include security audits,
        encryption of sensitive information, restricted access etc. Where we have given you (or
        where you have chosen) a password which enables you to access certain parts of our website,
        you are responsible for keeping this password confidential. We ask you not to share your
        passwords with anyone.
      </p>
      <p>
        <strong>Your rights</strong>
      </p>
      <p>
        <span className="text-underlined">Right to access</span>
      </p>
      <p>
        You have the right to ask us for copies of your personal information. This right always
        applies. There are some exemptions provided for in the law, especially when the rights and
        freedoms of others are adversely affected, which means you may not always receive all the
        information we process.
      </p>
      <p>
        We always aim to be as open as possible and to allow people access to their personal
        information. Where we hold your personal data, you can make a ‘subject access request’ to us
        and we will provide you with:
      </p>
      <ul>
        <li>a description of the personal data;</li>
        <li>an explanation of why we are holding it;</li>
        <li>information about who it could be disclosed to; and</li>
        <li>
          a copy of the information – unless an exception to the disclosure requirement is
          applicable.
        </li>
      </ul>
      <p>
        There is no specific format requirement for your access request. However, if you would like
        to make a ‘subject access request’, you are kindly requested to make it in writing to our
        DPO at <a href="mailto:pp@paschakislaw.com">pp@paschakislaw.com</a> and mark it clearly as
        ‘Subject Access Request’. We make every effort to examine and fulfil your request as soon as
        possible.
      </p>
      <p>
        <span className="text-underlined">Right to stop marketing messages</span>
      </p>
      <p>
        You always have the right to stop marketing messages. We will usually include an unsubscribe
        button in any marketing emails. If you do wish to unsubscribe, please just click the
        unsubscribe button and we will promptly action that request. Alternatively, you can update
        your marketing preferences by contacting us at anytime.
      </p>
      <p>
        <span className="text-underlined">Right to be forgotten</span>
      </p>
      <p>
        If we hold personal data about you, but it is no longer necessary for the purposes that it
        was collected and there are no alternative legal grounds otherwise justifying its
        processing, you have the right to request that we delete the data under the circumstances
        provided for by law.
      </p>
      <p>
        <span className="text-underlined">Right to rectification</span>
      </p>
      <p>
        You have the right to ask us to rectify information you think is inaccurate. You also have
        the right to ask us to complete information you think is incomplete. This right always
        applies.
      </p>
      <p>
        <span className="text-underlined">Right to restriction of processing</span>
      </p>
      <p>
        You have the right to ask us to restrict the processing of your information in certain
        circumstances.
      </p>
      <p>
        <span className="text-underlined">Right to data portability</span>
      </p>
      <p>
        This only applies to information you have given us. You have the right to ask that we
        transfer the information you gave us to someone else, or give it to you. The right only
        applies if we are processing information based on your consent or as a necessary step for
        entering into a contract with you, and the processing is automated. We will action this
        request, usually by sending the data in a CSV file.
      </p>
      <p>
        <span className="text-underlined">Right to complain</span>
      </p>
      <p>
        If you have any issue regarding how we have handled your personal data, you should first
        contact our Data Protection Officer at{' '}
        <a href="mailto:pp@paschakislaw.com">pp@paschakislaw.com</a> or{' '}
        <a href="mailto:iam@tatoiclub.com">iam@tatoiclub.com</a>. We will do our best to rectify the
        situation. Having done so, if you remain dissatisfied you can file a complaint with the
        Hellenic Data Protection Authority{' '}
        <a href="http://www.dpa.gr" target="_blank">
          http://www.dpa.gr
        </a>
        .
      </p>
      <p>
        <strong>What we do with children’s data</strong>
      </p>
      <p>
        We are committed to protecting the privacy needs of children and we encourage parents and
        guardians to take an active role in their children’s online activities and interests. We do
        not provide services directly to children under the age of 15. We take all the essential
        technical measures to assure as much as possible that the users of our digital services and
        of our app are not under the age of 15. We collect children’s personal information from the
        persons exercising parental care and responsibility, and we provide services to children
        only under the written consent of the proven holders of parental responsibility. We strictly
        limit the personal information that is publicly viewable about a member who is known by us
        to be a child. We do not subject children to decisions based solely on automated processing
        (including profiling). The information in the relevant parts of this notice applies to
        children as well as adults.
      </p>
      <p>
        <strong>Review of our Privacy and Data Protection Policy</strong>
      </p>
      <p>
        We regularly review our Privacy and Data Protection Policy to make sure it is up to date and
        accurate. Our last update: 10/04/2023.
      </p>
    </div>
  );
};

export default Privacy;
